import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permission-error',
  templateUrl: './permission-error.component.html',
  styleUrls: ['./permission-error.component.scss'],
})
export class PermissionErrorComponent implements OnInit {
  userRole: any;
  showReviewButton: any;
  viewDashboard: any;

  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit() {
    this.userRole = this.commonService.getSessionStorage('roles');
    this.showReviewButton = this.userRole?.includes("Review Patient Information")
    this.viewDashboard=this.userRole?.includes("Clinic Dashboard")
  }
  navigate() {
    this.router.navigate(['/clinic-dashboard']);
  }
}
