import { KeyValue } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';

@Component({
  selector: 'app-clinic-dashboard-patient-preview',
  templateUrl: './clinic-dashboard-patient-preview.component.html',
  styleUrls: ['./clinic-dashboard-patient-preview.component.scss'],
})
export class ClinicDashboardPatientPreviewComponent implements OnInit {

  showText: string = 'show all';
  isUpdated: boolean = true;
  markChecked: boolean = false;
  patients!: any;
  patientDetail: Array<any> = [];
  pcpDetails: any = [];
  patientEmergency: Array<any> = [];
  patientDetailCurrent!: any;
  patientDetailsUpdated!: any;
  patientDemographicsUpdated: Array<any> = [];
  patientDemographicsCurrent: Array<any> = [];
  PCPDetailsList: Array<any> = [];
  ReferrefDetailsList: Array<any> = [];
  sessionId: string = ''; //appiontmentDetailId
  listofFilledForms = {
    "Filled Forms": {
      "Formname": ""
    }

  };
  insurance: Array<any> = []
  pharmacy: Array<any> = []
  insurancePrimary: Array<any> = [];
  insuranceSecondary: Array<any> = [];
  insuranceTertiary: Array<any> = [];
  keyFilterInsurance: Array<any> = [];
  @ViewChildren("checked") checked !: QueryList<any>;
  formArray: any;
  calulatePatient: number = 0;
  calulateEmergency: number = 0;
  calculatePCP: number = 0;
  calculatereferral: number = 0;
  calulatePrimaryInsurance: number = 0;
  calulateSecondaryInsurance: number = 0;
  calulateTertiaryInsurance: number = 0;
  show: boolean = true;
  patientInfo!: any;
  done = false;
  closepopup: boolean = true
  isPatientFormOpened: boolean = false;
  submitPreRegister: { [x: string]: string } = {};
  tenantId: any
  patientContactDetail: any;
  patientEmergencyDetail: any;
  combinedDetails: any;
  insuranceDetails: Array<any> = [];
  userId: any;
  isAccepted: boolean = false;
  pcpObject: any;
  refObject: any;
  pharmacyName: any;
  submitPharmacy = [];
  pcpContactDetails: any;
  referral: any;
  referralDetails = [];
  referralSubmit = []
  constructor(
    private commonService: CommonService,
    private actRoute: ActivatedRoute,
    private dashboardService: DashboardStoreService,
    private patientService: PatientService,
    private router: Router,
    private auditService: AuditService,
    private appointService: AppointmentService
  ) {
    this.patientService.closepopup.subscribe(res => {
      this.closepopup = res
      if (this.closepopup == false) {
        this.hidePatientForm(this.closepopup)
      }
    })
  }

  ngOnInit() {
    this.sessionId = this.actRoute.snapshot.params['id'];
    this.tenantId = this.commonService.getSessionStorage('tnt');
    this.fullname = this.commonService.getSessionStorage("fn")
    this.actRoute.data.pipe(take(1)).subscribe({
      next: this.getAllResolvedData.bind(this),
    });
    // this.getForms();
    this.userId = this.commonService.getSessionStorage('PI');
  }

  getAllResolvedData(object: any) {
    this.patients = object[0].patient
    this.insurance = object[0].insurance
    this.pharmacy = object[0].pharmacy
    this.pcpObject = object[0].pcp
    this.patientInfo = object[0].patientInfo
    this.referral = object[0].referral
    let dob = new Date(this.patientInfo.dob)
    this.patientInfo.dob = new Date(dob.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata', formatMatcher: 'best fit' }));
    let session: any = this.commonService.getSessionStorage('sessionid')
    this.submitPreRegister['patientid'] = this.patientInfo['id']
    this.submitPreRegister['stenantid'] = this.patientInfo['tenantid']
    this.submitPreRegister['sessionid'] = session

    this.patients.map((item: any) => {

      if (item.category == "Patient Details") {

        this.patientDetail.push(item)
      }

      else
        this.patientEmergency.push(item)
    })
    this.patientDetail.map((item: any) => {
      if (!item.values_match) this.calulatePatient += 1
    })
    this.patientEmergency.map((item: any) => {
      if (!item.values_match) this.calulateEmergency += 1
    })

    this.pcpObject.map((item: any) => {
      if (item.category == "Other Details") {
        const relevantFields = ["prefix", "firstname", "middlename", "lastname", "suffix"];
        if (relevantFields.includes(item.col_name)) {
           this.pcpDetails.push(item);
        }
      }
    });

    this.referral.map((item: any) => {
      if (item.category == "Other Details") {
         const relevantFields = ["prefix", "firstname", "middlename", "lastname", "suffix"];
        if (relevantFields.includes(item.col_name)) {
          this.referralDetails.push(item); 
        }
      }
     });

     
    this.referralDetails.map((item: any) => {
      if (!item.values_match) this.calculatePCP += 1
    })

    this.pcpDetails.map((item: any) => {
      if (!item.values_match) this.calculatereferral += 1
    })

    this.pharmacy.map((item: any) => {
      if (item.col_name == 'spharmacyname') this.pharmacyName = item
    })
    
    if (this.referralDetails.length != 0) {
      this.referralDetails = this.simplifyreferralName(this.referralDetails, "Other Details")
       this.ReferrefDetailsList.push(...this.referralDetails)
      
    }

    if (this.pcpDetails.length != 0) {
      this.pcpDetails = this.simplifyPCPName(this.pcpDetails, "Other Details")
      this.PCPDetailsList.push(...this.pcpDetails)

    }

    this.patientContactDetail = this.patients
      .filter((item: any) => item.category === "Patient Details" && !item.values_match)
      .map((item: any) => {
        return {
          col_name: item.col_name,
          existing_value: item.existing_value,
          updated_value: item.updated_value,
          category: item.category
        };
      });
    this.patientEmergencyDetail = this.patients
      .filter((item: any) => item.category !== "Patient Details" && !item.values_match)
      .map((item: any) => {
        return {
          col_name: item.col_name,
          existing_value: item.existing_value,
          updated_value: item.updated_value,
          category: item.category
        };
      });
    this.combinedDetails = [...this.patientContactDetail, ...this.patientEmergencyDetail];

    this.pcpContactDetails = this.pcpObject
      .filter((item: any) => item.category == "Other Details" && !item.values_match)
      .map((item: any) => {
        return {
          col_name: item.col_name,
          existing_value: item.existing_value,
          updated_value: item.updated_value,
          category: item.category
        };
      });

    this.insurance?.map((item: any) => {
      if (item.col_name != "sinsuranceplanid" && item.col_name != "ninsuranceflag" && item.col_name != "nrelationshipid" && item.col_name != "sinstypecodedefault" && item.col_name != "sinstypedescdefault")
        if (item.category == "Primary Insurance") {
          this.insurancePrimary.push(item)
          if (!item.values_match) this.calulatePrimaryInsurance += 1
        }
        else if (item.category == "Secondary Insurance") {
          this.insuranceSecondary.push(item)
          if (!item.values_match) this.calulateSecondaryInsurance += 1
        } else if (item.category == "Tertiary Insurance") {
          this.insuranceTertiary.push(item);
          if (!item.values_match) this.calulateTertiaryInsurance += 1
        }
    })

    if (this.insurancePrimary.length != 0) {
      this.insurancePrimary = this.simplifySubscriberName(this.insurancePrimary, "Primary Insurance")
      this.insuranceDetails.push(...this.insurancePrimary)
    }
    if (this.insuranceSecondary.length != 0) {
      this.insuranceSecondary = this.simplifySubscriberName(this.insuranceSecondary, "Secondary Insurance")
      this.insuranceDetails.push(...this.insuranceSecondary)
    }

    if (this.insuranceTertiary.length != 0) {
      this.insuranceTertiary = this.simplifySubscriberName(this.insuranceTertiary, "Tertiary Insurance")
      this.insuranceDetails.push(...this.insuranceTertiary)
    }

  }


  simplifySubscriberName(insurance: any, type: string) {
    let subName: any[] = []
    let self = insurance.filter((item: any) => item.col_name == 'srelationship')[0];
    subName = insurance.filter((item: any) => (item.attribute == 'SubscriberFName' || item.attribute == 'SubscriberMName' || item.attribute == 'SubscriberLName'));
    insurance = insurance.filter((item: any) => !(item.attribute == 'SubscriberFName' || item.attribute == 'SubscriberMName' || item.attribute == 'SubscriberLName'))
    let matched_value = subName.filter((item: any) => item.values_match == false).length == 0
    let subscriberInsurance = {
      "col_name": subName.map((item: any) => item.col_name).join(" "),
      "attribute": "Subscriber Name",
      "existing_value": subName.map((item: any) => item.existing_value).join("|"),
      "updated_value": subName.map((item: any) => item.updated_value).join("|"),
      "values_match": matched_value,
      "category": type
    }
    insurance.push(subscriberInsurance)
    if (self.updated_value !== null) {
      if (self.updated_value == 'Self') {
        insurance = insurance.filter((item: any) => !(item.attribute == "Subscriber Date Of Birth" || item.attribute == "Subscriber Name"))
      }
    }
    else
      insurance = insurance.filter((item: any) => !(item.attribute == "Subscriber Date Of Birth" || item.attribute == "Subscriber Name"))
    return insurance
  }

  simplifyPCPName(pcp: any, type: string) {
    let pcpName = pcp?.filter((item: any) => (
      item.attribute === 'Prefix' ||
      item.attribute === 'First Name' ||
      item.attribute === 'Middle Name' ||
      item.attribute === 'Last Name' ||
      item.attribute === 'Suffix'
    ));
    pcp = pcp?.filter((item: any) => !(
      item.attribute === 'Prefix' ||
      item.attribute === 'First Name' ||
      item.attribute === 'Middle Name' ||
      item.attribute === 'Last Name' ||
      item.attribute === 'Suffix'
    ));
    let matched_value = pcpName?.filter((item: any) => item.values_match === false).length === 0;
  
    let existing_value_parts = pcpName.map((item: any) => item.existing_value).filter((val: string) => val);
    let updated_value_parts = pcpName.map((item: any) => item.updated_value).filter((val: string) => val);
  
    let PCPName = {
      "col_name": pcpName?.map((item: any) => item.col_name).join(" "),
      "attribute": "Primary Care Physician",
      "existing_value": existing_value_parts.join(" "),
      "updated_value": updated_value_parts.join(" "),
      "values_match": matched_value,
      "category": type
    };
    pcp.push(PCPName);
    return pcp;
  }

  simplifyreferralName(referr: any, type: string) {
    let referralName = referr?.filter((item: any) => (
      item.attribute === 'Prefix' ||
      item.attribute === 'First Name' ||
      item.attribute === 'Middle Name' ||
      item.attribute === 'Last Name' ||
      item.attribute === 'Suffix'
    ));
    referr = referr?.filter((item: any) => !(
      item.attribute === 'Prefix' ||
      item.attribute === 'First Name' ||
      item.attribute === 'Middle Name' ||
      item.attribute === 'Last Name' ||
      item.attribute === 'Suffix'
    ));
    let matched_value = referralName?.filter((item: any) => item.values_match === false).length === 0;
  
    let existing_value_parts = referralName.map((item: any) => item.existing_value).filter((val: string) => val);
    let updated_value_parts = referralName.map((item: any) => item.updated_value).filter((val: string) => val);
  
    let RefName = {
      "col_name": referralName?.map((item: any) => item.col_name).join(" "),
      "attribute": "referral Name",
      "existing_value": existing_value_parts.join(" "),
      "updated_value": updated_value_parts.join(" "),
      "values_match": matched_value,
      "category": type + 'ref'
    };
    referr.push(RefName);
    return referr;
  }
  

  showUpdated() {
    if (this.showText == 'show all') {
      this.showText = 'show updated';
      this.isUpdated = false;
    } else {
      this.showText = 'show all';
      this.isUpdated = true;
    }
  }

  checkAll() {
    this.markChecked = !this.markChecked;
    this.checked.forEach((el: any) => {
      el.nativeElement.checked = this.markChecked;
      el.nativeElement.dispatchEvent(new Event('change'));
    });
    this.checkedShow();
  }

  checkedShow() {
    var checkValue = 0
    this.checked.forEach((el: any) => {
      if (el.nativeElement.checked) checkValue += 1
    });
    if (checkValue == 0) {
      this.show = true;
      this.markChecked = false;
    }
    else
      this.show = false;
  }

  singleCheckboxChange(event: any, col: string, value: string, category: string) {
    if (event.target.checked) {
      if (value !== null) {
        if (value.indexOf("|") !== -1) {
          let colKey = col.split(" ")
          let valKey = value.split("|")
          colKey.forEach((item: any, iterators: number) =>
            this.submitPreRegister[colKey[iterators]] = valKey[iterators]
          )

        }
        else
          this.submitPreRegister[col] = value;

      }
    }
    else {
      if (value !== null)
        if (value.indexOf("|") !== -1) {
          let colKey = col.split(" ")
          colKey.forEach((item: any, iterators: number) =>
            delete this.submitPreRegister[colKey[iterators]]
          )
        }

      delete this.submitPreRegister[col];
    }
    const detailIndex = this.combinedDetails.findIndex((detail: any) => detail.col_name === col);
    if (detailIndex !== -1) {
      this.combinedDetails[detailIndex].ischecked = event.target.checked;
    }
    const detailpcpIndex = this.pcpContactDetails.findIndex((detail: any) => detail.col_name === col);
    if (detailpcpIndex !== -1) {
      this.pcpContactDetails[detailpcpIndex].ischecked = event.target.checked;
    }

    if (col === "prefix firstname middlename lastname suffix" && category !== 'Other Detailsref') {
      const relevantFields = ["prefix", "firstname", "middlename", "lastname", "suffix"];
      const additionalFields = ["contactid", "city", "fax", "email", "phone", "state", "gender", "addressline1", "addressline2", "zip"]; // Add any other fields you want to set as checked by default
      const allFields = [...relevantFields, ...additionalFields];

      allFields.forEach((field: string) => {
        const index = this.pcpContactDetails.findIndex((detail: any) => detail.col_name === field);
        if (index !== -1) {
          this.pcpContactDetails[index].ischecked = event.target.checked;
        } else if (event.target.checked) {
          const originalField = this.pcpObject.find((detail: any) => detail.col_name === field);
          if (originalField) {
            this.pcpContactDetails.push({
              col_name: originalField.col_name,
              existing_value: originalField.existing_value,
              updated_value: originalField.updated_value,
              category: originalField.category,
              ischecked: true
            });
          }
        }
      });
      // Ensure all fields have correct ischecked value
      if (!event.target.checked) {
        allFields.forEach((field: string) => {
          const index = this.pcpContactDetails.findIndex((detail: any) => detail.col_name === field);
          if (index !== -1) {
            this.pcpContactDetails[index].ischecked = false;
          }
        });
      }
    }
    if (col === "prefix firstname middlename lastname suffix" && category == 'Other Detailsref') {
      const relevantFields = ["prefix", "firstname", "middlename", "lastname", "suffix"];
      const additionalFields = ["contactid", "city", "fax", "email", "phone", "state", "gender", "addressline1", "addressline2", "zip"]; // Add any other fields you want to set as checked by default
      const allFields = [...relevantFields, ...additionalFields];
      if(event.target.checked)
      allFields.forEach((field: string) => {
        const index = this.referralDetails.findIndex((detail: any) => detail.col_name === field);
        if (index !== -1) {
          this.referralDetails[index].ischecked = event.target.checked;
        } else if (event.target.checked) {
          const originalField = this.referral.find((detail: any) => detail.col_name === field);
          if (originalField) {
            this.referralSubmit.push({
              col_name: originalField.col_name,
              existing_value: originalField.existing_value,
              updated_value: originalField.updated_value,
              category: originalField.category,
              ischecked: true
            });
          }
        }
      }); 
      // Ensure all fields have correct ischecked value
    }

    const detailIndexInsurance = this.insuranceDetails.findIndex((detail: any) => (detail.col_name === col) && (detail.category == category));
    if (detailIndexInsurance !== -1) {
      this.insuranceDetails[detailIndexInsurance].ischecked = event.target.checked;
    }
    if(event.target.checked == true && col == 'spharmacyname') {
      this.pharmacy.map((item: any) => {
        let items = {
          "category": item.category,
          "col_name": item.col_name,
          "existing_value": item.existing_value ? item.existing_value: '',
          "updated_value": item.updated_value ? item.updated_value: '',
          "ischecked": true
      }  
        if(!item.values_match)
        this.submitPharmacy.push(items)
      }) 
    }
    
    if(col == 'spharmacyname') {
      if(event.target.checked !== true )
      this.submitPharmacy = []
    }
    this.checkedShow()
  }

  submitPreRegisters(event: any, action: any) {
    this.isAccepted = true;
    const demographics: any[] = [];
    const insurances: any = {};
    const pcp: any[] = []

    // Temporary map to track processed items
    const processedItems: Map<string, boolean> = new Map();

    this.combinedDetails.forEach((detail: any) => {

      let col_name = detail.col_name;
      let existing_value = detail.existing_value;
      let updated_value = detail.updated_value;
      let ischecked = detail.ischecked || false;

      if (col_name == 'smobile' || col_name == 'sphone' || col_name == 'semergencymobile') {
        existing_value = existing_value.replace(/[()-]/g, '')
        updated_value = updated_value.replace(/[()-]/g, '')
      }

      demographics.push({
        category: detail.category,
        col_name,
        existing_value,
        updated_value,
        ischecked
      });
    });


    this.pcpContactDetails.forEach((detail: any) => {

      let col_name = detail.col_name;
      let existing_value = detail.existing_value;
      let updated_value = detail.updated_value;
      let ischecked = detail.ischecked || false;


      pcp.push({
        category: detail.category,
        col_name,
        existing_value,
        updated_value,
        ischecked
      });
    });

    this.insuranceDetails.forEach((insuranceItem: any) => {
      if (insuranceItem?.col_name == 'srelationship') {
        if (insuranceItem?.existing_value == insuranceItem?.updated_value) {
          insuranceItem.ischecked = true;
        }
      }
      const col_name = insuranceItem.col_name;
      const existing_value = insuranceItem.existing_value;
      const updated_value = insuranceItem.updated_value;
      const ischecked = insuranceItem.ischecked !== undefined ? insuranceItem.ischecked : false;

      if (insuranceItem.col_name !== 'ssubfname ssubmname ssublname') {
        const categoryKey = insuranceItem.category.toLowerCase();
        if (!insurances[categoryKey]) {
          insurances[categoryKey] = [];
        }

        const itemKey = JSON.stringify(insuranceItem);
        if (!processedItems.has(itemKey)) {
          insurances[categoryKey].push({
            category: insuranceItem.category,
            col_name: col_name !== null ? col_name : '',
            existing_value: existing_value !== null ? existing_value : '',
            updated_value: updated_value !== null ? updated_value : '',
            ischecked
          });
          // Mark item as processed
          processedItems.set(itemKey, true);
        }
      }
    });

    this.insurance?.forEach((item: any) => {
      const insuranceItem = this.buildInsuranceItem(item);
      if (insuranceItem) {
        const categoryKey = item.category.toLowerCase();
        if (!insurances[categoryKey]) {
          insurances[categoryKey] = [];
        }
        // Check if item is already processed
        const itemKey = JSON.stringify(insuranceItem);
        if (!processedItems.has(itemKey)) {
          insurances[categoryKey].push(insuranceItem);

          // Mark item as processed
          processedItems.set(itemKey, true);
        }
      }
    });

    // Filter out insurance categories where all values_match are true
    const filteredInsurances: any = {};
    Object.keys(insurances).forEach((categoryKey) => {
      const insuranceCategory = insurances[categoryKey];
      const allValuesMatch = insuranceCategory.every((item: any) => item.existing_value == item.updated_value);
      if (!allValuesMatch) {
        filteredInsurances[categoryKey] = insuranceCategory;
      }
    });

    // Remove "insurance" from object names
    const updatedInsurances: any = {};
    Object.keys(filteredInsurances).forEach((key) => {
      const newKey = key.replace(' insurance', '');
      updatedInsurances[newKey] = filteredInsurances[key];
    });

    // Check if all insurance categories are filtered out
    const isEmptyInsurances = Object.keys(updatedInsurances).length === 0;

    const payload = {
      patientid: this.submitPreRegister['patientid'],
      tenantid: this.submitPreRegister['stenantid'],
      sessionid: this.submitPreRegister['sessionid'],
      reviewedby: this.userId.userid,
      demographics,
      pharmacy: this.submitPharmacy ? this.submitPharmacy : [],
      pcp,
       referral: this.referralSubmit ? this.referralSubmit : [],
      insurance: isEmptyInsurances ? {} : updatedInsurances
    }; 
     if (payload) {
      this.dashboardService.publishDetails(payload).subscribe({
        next: (response: any) => {
          this.appointService.triggerLoaderClick1()
          this.auditService.writeOparationalLog('Review Patient Info#' + this.fullname + '#' + this.patientInfo.patientcode + "#" + this.patientInfo.id);
          this.appointService.triggerLoaderClick1()
          this.router.navigateByUrl("/clinic-dashboard");

        },
        error: (error: any) => {
          this.isAccepted = false;
          console.error('Error occurred:', error);
        }
      });
    }
  }



  buildInsuranceItem(item: any): any {
    if (
      item.col_name == 'sinsuranceplanid' ||
      item.col_name == 'nrelationshipid' ||
      item.col_name == 'ssubfname' ||
      item.col_name == 'ssubmname' ||
      item.col_name == 'ssublname' ||
      item.col_name == 'ninsuranceflag' ||
      item.col_name == 'ssubscriberdob'
    ) {
      return {
        category: item.category,
        col_name: item.col_name,
        existing_value: item.existing_value !== null ? item.existing_value : '',
        updated_value: item.updated_value !== null ? item.updated_value : '',
        ischecked: (item.existing_value !== null && item.existing_value !== '') || (item.updated_value !== null && item.updated_value !== '')
      };
    }
    if (item.col_name == 'sinstypecodedefault') {
      return {
        category: item.category,
        col_name: item.col_name,
        existing_value: item.existing_value !== null ? item.existing_value : '',
        updated_value: item.updated_value !== null ? item.updated_value : 'OT',
        ischecked: item.ischecked || true
      };
    }
    if (item.col_name == 'sinstypedescdefault') {
      return {
        category: item.category,
        col_name: item.col_name,
        existing_value: item.existing_value !== null ? item.existing_value : '',
        updated_value: item.updated_value !== null ? item.updated_value : 'Other',
        ischecked: item.ischecked || true
      };
    }
    if (item.col_name === 'ssubscribergender') {
      return {
        category: item.category,
        col_name: item.col_name,
        existing_value: item.existing_value !== null ? item.existing_value : '',
        updated_value: item.updated_value !== null ? item.updated_value : 'Other', // Set default value to 'Other'
        ischecked: item.ischecked !== undefined ? item.ischecked : true
      };
    }
  }

  originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return 0;
  };


  // getForms() {
  //   let appointmentId = this.commonService.getSessionStorage("appointmentid") 
  //   this.patientService.getFormbyType(this.tenantId,appointmentId).subscribe({
  //     next: (response: any) => {
  //       this.formArray = response;
  //     },
  //     error: (err: any) => {

  //     },
  //     complete: () => {
  //     }
  //   });
  // }
  hideformpopup(e: any) {
    this.done = e;
    this.isPatientFormOpened = false;
  }
  singleFormOpen(formid: any) {
    this.patientService.previewForm.next(formid)
    this.showPatientForm();
  }
  showPatientForm() {
    this.isPatientFormOpened = true;
  }
  hidePatientForm(result: boolean) {

    this.isPatientFormOpened = result;
  }
  fullname: string = '';
  closeClicked() {
    this.fullname = this.commonService.getSessionStorage("fn")
    console.log(this.patientInfo)
    this.auditService.writeOparationalLog('Review Patient Close#' + this.fullname + "#" + this.patientInfo.id + "#" + this.patientInfo.patientcode);
    this.router.navigateByUrl("/clinic-dashboard");
  }
}