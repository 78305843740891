<app-welcome-header></app-welcome-header>
<div *ngIf="loadingPanelVisible" class="k-i-loading loading-overlay"></div>
<div class="mid-container">
  <div class="mid-comment-border mid-header-section">
    <div class="contentBox main_container">
      <form class="verification-box k-form column"  [formGroup]="otpform">
            <ion-text class="bodyXL-txt-bold mb-10" color="gray100">Enter verification code</ion-text>
              <ion-text class="bodyM-txt-italic mb-20" color="gray80">Enter the 6 digit code sent to <span class="no-wrap">{{getPhoneNumber}}
                <!-- (357) 999-4444 -->
              </span>.  
              </ion-text>
          <div class="ion-text-center " >
            <kendo-formfield>
              <div class="d-flex ">
                <!-- (focus)="enableVerify()" -->
                <kendo-textbox [clearButton]="true" (input)="onInput($event)" class="bodyL-txt w-100 mx-30" color="gray100" #code 
                formControlName="code" appNumericOnly inputmode="numeric" required>
              </kendo-textbox>
          </div>
          <!-- <kendo-formerror class="bodyM-txt mx-30" *ngIf="isShowError">Incorrect code</kendo-formerror> -->
          <!-- <kendo-formerror class="bodyM-txt mx-30"
            *ngIf="otpform.get('code')?.errors?.['pattern'] ||  otpform.get('code')?.value.length !== 6">Please enter
            only numeric value.</kendo-formerror> -->
              <div *ngIf="countdown>0">{{ countdown }} second remaining</div>
              <div *ngIf="verificationError" class="error-message">
                Please enter the correct verification code.
              </div>
          </kendo-formfield>
  
          </div>
            <div class="ion-text-center column w-100">
              <ion-button [disabled]="!isInputValid() || isSendButtonEnabled" fill="solid" color="ion-color-white" class="filled-icon-btn capitalize my-20" (click)="otpVerification()">
                <ion-text>Verify</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
              </ion-button>
  
              <ion-text  class="bodyM-txt-italic" color="gray80" >
                Don’t see the text message? <br></ion-text>
              <ion-text [class.disabled-text]="isDisabled" (click)="resendOTP()" color="highlight bodyM-txt-italic">Send a new code
              </ion-text>
            </div>
      </form>
    </div>
  </div>
</div>
<app-idle></app-idle>