import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, catchError, of } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { PaymentService } from 'src/app/services/payment.service';
import { AuditService } from '../../pages/AuditService/audit.service';
import { clinicEnum } from 'src/app/core/Enums/clinic.enum';

@Component({
  selector: 'app-clinic-lock-screen',
  templateUrl: './clinic-lock-screen.component.html',
  styleUrls: ['./clinic-lock-screen.component.scss']
})
export class ClinicLockScreenComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('passwordInput')
  private passwordInput!: TextBoxComponent;
  isDesktop!: boolean;
  @ViewChild('username')
  private username!: TextBoxComponent;
  isIncorrect: boolean = true;
  isResetPasswordForm: boolean = true;
  isResetPassword: boolean = true;
  isForgetLink: boolean = true;
  public maxlength = 1;
  loginForm!: FormGroup<any>;
  public errorMessage!: string;
  //log("login");
  subscription: Subscription = new Subscription();
  public userloggedin: boolean = false;
  noPracticeListError1: string = '';
  noPracticeListError2: string = '';
  showError: boolean = false;

  isPinError: boolean = false;
  isResetBtn: boolean = true;
  isPatient: boolean = false
  loginResult: any;
  userRole: any;

  returnRoute!: any;

  breakpoint: any = window.matchMedia('(min-width: 680px)');

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  deviceInfo: import("ngx-device-detector").DeviceInfo | undefined;

  constructor(private fb: FormBuilder,
    private alertController: AlertController,
    private loginService: LoginService,
    private router: Router,
    private commonService: CommonService,
    public loadingController: LoadingController,
    private paymentService: PaymentService,
    private deviceService: DeviceDetectorService,
    private auditService: AuditService,
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.isDesktop = !!this.breakpoint.matches;
    this.epicFunction();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(this.isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(this.isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  ngOnInit() {
    this.returnRoute = this.commonService.getSessionStorage('returnLocation')
  }
  public ngAfterViewInit() {
    this.passwordInput.input.nativeElement.type = 'password';
    this.username.input.nativeElement.type = 'text';
  }

  ngAfterViewChecked() {
    // Check if the input element exists and set its type to "password"
    if (this.passwordInput && this.passwordInput.input) {
      this.passwordInput.input.nativeElement.type = 'password';
    }
  }
  showIncorrectPin() {
    this.isIncorrect = false;
  }
  showForgetPass() {
    this.isResetPassword = false;
  }
  showBacktoLogin() {
    this.isResetPasswordForm = false;
  }
  showForgetLink() {
    this.isForgetLink = false;
    if (this.loginForm.value.password.length == 0) {
      this.isForgetLink = true;
    }
  }
  backtoLogin() {
    this.isResetPassword = true;
  }

  showLoader() {
    this.loadingController
      .create({
        message: 'Please wait...',
      })
      .then((res) => {
        res.present();
      });
  }
  hideLoader() {
    this.loadingController
      .dismiss()
      .then((res) => {
        //console.log('Loading dismissed!', res);
      })
      .catch((error) => {
        // console.log('error', error);
      });
  }
  //function for  authentication
  async onSubmit() {
    if (this.loginForm.valid == true) {
      let username = this.loginForm.value.username;
      let password = this.loginForm.value.password;
      this.loginService
        .doLogin({
          email: username,
          password: password,
        })
        .then(
          (res: any) => {
            this.commonService.setLocalStorage('user', JSON.stringify(res.user));
            this.commonService.setLocalStorage('type', '1');
            this.showLoader()
            this.commonService.setSessionStorage('isPt', JSON.stringify(this.isPatient));
            this.getLoginUserDetails();
            this.errorMessage = ''
            // this.router.navigate(['/clinic-dashboard'])
          },
          (err: any) => {
            if (err.code === 'auth/invalid-email') {
              this.errorMessage = 'The email or password you entered is incorrect. Please check and try again.';
            } else if (err.code == 'auth/too-many-requests') {
              this.errorMessage =
                'Too many unsuccessful login attempts. For security reasons your account is locked temporarily. Please try again later.';
            } else if (err.code == 'auth/wrong-password') {
              this.errorMessage = 'The email or password you entered is incorrect. Please check and try again.';
            } else if (err.code == 'auth/user-not-found') {
              this.errorMessage =
                // 'The email is invalid';
                'The email or password you entered is incorrect. Please check and try again';
            } else {
              this.errorMessage ='The email or password you entered is incorrect. Please check and try again'

            }
          }
        );
    }
  }

  // async alertLoginFailed(msg: String) {
  //   const alert = await this.alertController.create({
  //     header: 'Login failed',
  //     message: msg.toString(),
  //     buttons: ['Ok'],
  //   });
  //   await alert.present();
  // }

  //function for to get the user details.
  async getLoginUserDetails() {
    this.loginService.getCurrentUser().then((fireUser: any) => {
      this.subscription.add(
        this.loginService
          .getAccessUserDetails(fireUser.uid, fireUser._delegate.accessToken)
          .subscribe((resData: any) => {
            this.commonService.setSessionStorage('UID', JSON.stringify(fireUser.uid));
            this.noPracticeListError1 = '';
            this.noPracticeListError2 = '';
            //this.router.navigate(['/clinic-dashboard']);

            let obj = {
              firstname: resData?.firstname,
              lastname: resData?.lastname,
              username: resData?.username,
              userid: resData?.userid,
              token: resData?.token
            }
            this.commonService.setSessionStorage('fn', JSON.stringify(resData?.firstname + " " + resData?.lastname))
            this.commonService.setSessionStorage('usid', JSON.stringify(resData?.userid))
            this.commonService.setSessionStorage('usr', JSON.stringify(resData?.username))
            this.commonService.setSessionStorage('PI', JSON.stringify(obj))
            this.auditService.writeOparationalLog('Login User#' + resData?.userid + "#" + resData?.firstname + " " + resData?.lastname);
            this.getUserImage(obj.userid, obj.token);
            this.loginService.getQoreImage(obj?.userid, obj?.token).subscribe((response: any) => {
              const userPermissions = response?.userPermissions;
              this.commonService.setSessionStorage(
                'roles',
                JSON.stringify(userPermissions.map((m: any) => m.permissiondescription))
              );
              this.getQoreUserDetails(fireUser.uid, resData.token,);
            })
          })
      );
    });
  }

  getUserImage(uid: string, token: string) {
    this.loginService.getQoreImage(uid, token).subscribe({
      next: (response: any) => {
        let imageUser = {
          username: response.username,
          url: response.url,
          firstname: response.firstname,
          lastname: response.lastname
        }
        this.commonService.setSessionStorage('url', JSON.stringify(imageUser))
      }
    })
  }

  getQoreUserDetails(uid: any, token: any) {
    this.userRole = this.commonService.getSessionStorage('roles');
    let redirectTo =this.commonService.getSessionStorage('redirectTo');

    this.subscription.add(
      this.loginService.getQoreUserDetails(uid, token).subscribe((response: any) => {
        if (response) {
          this.hideLoader();
          if (Array.isArray(response.tenants) && response.tenants.length === 1) {
            // If there's only one tenant, navigate to the Clinic Dashboard and store the tenant ID
            const selectedTenantId = response.tenants[0].tenantid; // Assuming you want to navigate to the first tenant

            this.commonService.setSessionStorage('tnt', JSON.stringify(response.tenants[0].tenantid));
            this.commonService.setSessionStorage('TN', JSON.stringify(response.tenants[0].tenantname));
            // try {
            //   // console.log("Setting cleargage config");
            //   this.clearGageCredentials(response.tenants[0].tenantid);
            // } catch {
            // }
            if( redirectTo == 'auditreport' ){
              if (this.userRole?.includes("Audit Report")) {
                this.router.navigate(['/app/audit-report']);
              } else if (!this.userRole?.includes("Audit Report")) {
                this.loginService.permissionError();
              }
            }else if( redirectTo == 'usagereport' ){
              if (this.userRole?.includes("Audit Report")) {
                this.router.navigate(['/app/usage-report']);
              } else if (!this.userRole?.includes("Audit Report")) {
                this.loginService.permissionError();
              }
            } else{
              if (this.userRole?.includes("Clinic Dashboard")) {
                this.router.navigate(['/clinic-dashboard']);
              } else if (!this.userRole?.includes("Clinic Dashboard")) {
                this.loginService.permissionError();
              }
            }        



          } else if (response.tenants.length === 0) {
            // If there are no tenants, show an error message
            this.noPracticeListError1 = 'You do not have any Practice assigned';
            this.noPracticeListError2 = 'Please contact your administrator.';
            this.showError = true;
          }

          else {
            const storedTenantId = this.commonService.getSessionStorage('tnt');
            let practDetails = this.commonService.getLocalStorage('practiceDetails');
            if (practDetails && practDetails.tenantid) {
              const selectedTenantId = practDetails.tenantid;
              this.commonService.setSessionStorage('tnt', JSON.stringify(practDetails.tenantid));;
              this.commonService.setSessionStorage('TN', JSON.stringify(practDetails.tenantname));
        
              if( redirectTo == 'auditreport' ){
                if (this.userRole?.includes("Audit Report")) {
                  this.router.navigate(['/app/audit-report']);
                } else if (!this.userRole?.includes("Audit Report")) {
                  this.loginService.permissionError();
                }
              }else{
                if (this.userRole?.includes("Clinic Dashboard")) {
                  this.router.navigate(['/clinic-dashboard']);
                } else if (!this.userRole?.includes("Clinic Dashboard")) {
                  this.loginService.permissionError();
                }
              }             
            } else if (!storedTenantId) {
              if( redirectTo == 'auditreport' ){
                if (this.userRole?.includes("Audit Report")) {
                  this.router.navigate(['/practice-list']);
                } else if (!this.userRole?.includes("Audit Report")) {
                  this.loginService.permissionError();
                }
              }else{
                if (this.userRole?.includes("Clinic Dashboard")) {
                  this.router.navigate(['/practice-list']);
                } else if (!this.userRole?.includes("Clinic Dashboard")) {
                  this.loginService.permissionError();
                }
              }  

            } else {
              if (this.returnRoute && this.returnRoute.check) {
                let returnLoc = JSON.stringify({ loc: this.returnRoute.loc, check: false })
                this.commonService.setSessionStorage("returnLocation", returnLoc)

                this.router.navigate([this.returnRoute.loc])
              }
              // else if (this.userRole?.includes("Clinic Dashboard")) {
              //     this.router.navigate(['/clinic-dashboard']);
              //   } else if (!this.userRole?.includes("Clinic Dashboard")) {
              //     this.loginService.permissionError();

              //   }
              else if( redirectTo == 'auditreport' ){
                if (this.userRole?.includes("Audit Report")) {
                  this.router.navigate(['/app/audit-report']);
                } else if (!this.userRole?.includes("Audit Report")) {
                  this.loginService.permissionError();
                }
              }else{
                if (this.userRole?.includes("Clinic Dashboard")) {
                  this.router.navigate(['/clinic-dashboard']);
                } else if (!this.userRole?.includes("Clinic Dashboard")) {
                  this.loginService.permissionError();
                }
              }  
            }
          }
          this.loginForm.get('username')?.setValue('');
          this.loginForm.get('password')?.setValue('');
        }
      })
    );
  }

  clearGageCredentials(tenantid: any) {
    this.paymentService.getTenantClearGageCredentials(tenantid).pipe(
      catchError(error => {
        console.error('Error occurred while fetching ClearGage credentials:', error);
        return of(null); // Return an empty observable to continue the stream
      })
    ).subscribe((response: any) => {
      if (response) {
        const cleargageCredentials = JSON.stringify(response.data);
        this.commonService.setSessionStorage('tenantcleargageCred', cleargageCredentials);
      } else {
        this.commonService.removeSessionStorageBykey('tenantcleargageCred');
      }
    });
  }

  showPinError() {
    this.isPinError = true;
  }
  showResetBtn() {
    this.isResetBtn = false;
  }

}
