import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private commonService:CommonService) { }

  isPatientLoggedIn() {
    if ((this.commonService.getLocalStorage('user') && this.commonService.getLocalStorage('type') && this.commonService.getLocalStorage('type') === '2')) {
      return true;
    } else {
      return false;
    }
  }

  isClinicPatientLoggedIn() {
    if ((this.commonService.getLocalStorage('user') && this.commonService.getLocalStorage('type') && this.commonService.getLocalStorage('type') === '3')) {
      return true;
    } else {
      return false;
    }
  }

  isPracticeLoggedIn() {
    if ((this.commonService.getLocalStorage('user') && this.commonService.getLocalStorage('type') && this.commonService.getLocalStorage('type') === '1')) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    sessionStorage.clear();
  }
}
