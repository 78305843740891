import { Component, OnInit, ViewChild } from '@angular/core';
import { PreRegistrationTimeoutWarningComponent } from '../pre-registration-timeout-warning/pre-registration-timeout-warning.component';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { WindowService } from 'src/app/core/services/window.service';
import { IdleService } from 'src/app/core/services/idle-service.service';

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { PatientService } from 'src/app/core/services/patient.service';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
})
export class IdleComponent implements OnInit {

  idleTimerLeft: string = '';
  secondTimerLeft: string = '';

  firstLevelTimer: number = 1;
  secondLevelTimer: number = 1;
  bsModalRef!: BsModalRef;
  @ViewChild('childModal', { static: false })
  childModal!: ModalDirective;


  constructor(private ngIdle: IdleService,
    private keepalive: Keepalive,
    private router: Router,
    private modalService: BsModalService,
    private windowService: WindowService,
    private patientService: PatientService) {


  }


  ngOnInit() {

    this.patientService.getSessionTimeOut().subscribe({
      next: (response: any) => {
        this.firstLevelTimer = parseInt(response.ssettingsvalue)
        this.initTimer(this.firstLevelTimer, this.secondLevelTimer);
      }
    })
  }


  initTimer(firstTimerValue: number, secondTimerValue: number): void {
    // Timer value initialization
    this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = secondTimerValue;
    // end

    // Watcher on timer
    this.ngIdle.initilizeSessionTimeout();
    this.ngIdle.userIdlenessChecker.subscribe((status: string) => {
      this.initiateFirstTimer(status);
    });

    this.ngIdle.secondLevelUserIdleChecker.subscribe((status: string) => {
      this.initiateSecondTimer(status);
    });
  }

  initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;

      case 'STOPPED_TIMER':
        this.bsModalRef = this.modalService.show(PreRegistrationTimeoutWarningComponent, { animated: true, backdrop: 'static', class: 'patient-reg' });

        break;

      default:
        this.idleTimerLeft = this.formatTimeLeft(Number(status));
        break;
    }
  }

  initiateSecondTimer = (status: string) => {

    switch (status) {
      case 'INITIATE_SECOND_TIMER':
        break;

      case 'SECOND_TIMER_STARTED':

        break;

      case 'SECOND_TIMER_STOPPED':

        this.logout();
        break;

      case 'SECOND_TIMER_STAY':
        if (this.bsModalRef)
          this.bsModalRef.hide()
        break;

      default:
        this.secondTimerLeft = status;
        break;
    }
  }


  formatTimeLeft = (time: number) => {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);

      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        seconds -= (min * 60);
      }

      return `${min}:${seconds}`;
    }
    return '00:00'
  }

  reset() {
    // this.idle.watch();
  }

  logout() {
    this.bsModalRef.hide();
    firebase
      .auth().signOut().then(() => {
        // this.presenceservice.signOut();
        sessionStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('type');
        // window.location.reload()
        this.router.navigate(['/clinic']);
      });
  }

}
