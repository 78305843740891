import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription, debounceTime, distinctUntilChanged, forkJoin, fromEvent, map, switchMap, take } from 'rxjs';
import { DashboardLocationModel } from 'src/app/core/models/dashboardLocationModel';
import { providerModel } from 'src/app/core/models/providerModel';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';

@Component({
  selector: 'app-clinic-dashboard-provider-list',
  templateUrl: './clinic-dashboard-provider-list.component.html',
  styleUrls: ['./clinic-dashboard-provider-list.component.scss'],
})
export class ClinicDashboardProviderListComponent implements OnInit {

  isNoResult: boolean = false;
  locationSelected: DashboardLocationModel[] = [];
  providerAll: providerModel[] = [];
  providerConst: providerModel[] = [];
  @ViewChild('searchProvider', { read: ElementRef }) searchProvider!: ElementRef;
  subscriptionSearch!: Subscription;
  searchTerm: string = '';
  showActive: boolean = true;
  allprov: boolean = true;
  public loadingPanelVisible = true;
  constructor(private dashboardStoreService: DashboardStoreService,
    private commonService: CommonService,
    private appointService: AppointmentService,
    private auditService: AuditService
  ) { }


  ngOnInit() {
    this.getTenantIds();
  }

  getTenantIds() {
    //let tenant = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('tenant'))));

    let tenant = this.commonService.getSessionStorage('tnt');
    if (tenant != null) {
      this.dashboardStoreService.locationObservable$.pipe().subscribe({
        next: this.getLocationSelectedByHandler.bind(this),
        error: this.apiErrorByHandler.bind(this, 'locationObservable$')
      })
    } else {
      setTimeout(() => this.getTenantIds(), 10)
    }
  }

  ngAfterViewInit() {
    // Keyup event on searchbar.
    this.subscriptionSearch = fromEvent(this.searchProvider.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe({

      next: this.updateSearchByHandler.bind(this)
    })
  }

  // Get location selected data int object.
  getLocationSelectedByHandler(object: DashboardLocationModel[]) {
    this.locationSelected = object;
    //let tenant = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('tenant'))));
    let tenant = this.commonService.getSessionStorage('tnt');
    let alltenant: Array<string> = []
    if (tenant != '')
      // tenant.map((item: any) => alltenant.push(item.tenantid))
      if (this.locationSelected.length != 0)
        //console.log('location',this.locationSelected[0]?.tenantid)
        this.dashboardStoreService.getAllProviderByLocation(this.locationSelected[0]?.tenantid).subscribe({
          next: this.getAllProviderByHandler.bind(this),
          error: this.apiErrorByHandler.bind(this, 'getAllProviderByLocation')
        })
    // Write logic for get provider with the help of location
  }

  // Get Provider Selected data into object.
  getAllProviderByHandler(object: providerModel[]) {
    this.loadingPanelVisible = false;
    this.providerAll = object || [];
    //console.log("All provider ==> ",this.providerAll)
    // this.providerAll.splice(2, 1);
    this.providerConst = this.providerAll;
    if (this.providerAll.length > 0) {
      this.isNoResult = false;
    }

    else
      this.isNoResult = true;
    this.dashboardStoreService.providerBehaviour.next(this.providerAll);
    //console.log('provider',this.providerAll);
  }

  // filtering the data on the basis of keyup event.
  updateSearchByHandler(search: any) {
    // console.log("search=>", search);
    this.auditService.writeOparationalLog('search provider#' + search);
    this.providerAll = this.providerConst.filter((item: any) =>
      item.providerfirstname.toLowerCase().includes(search.toLowerCase()) || item.providerlastname.toLowerCase().includes(search.toLowerCase())
    )
    this.searchTerm = search
    if (search != '')
      this.allprov = false
    else
      this.allprov = true

    if (this.providerAll.length > 0)
      this.isNoResult = false;
    else
      this.isNoResult = true;
    this.dashboardStoreService.providerBehaviour.next(this.providerAll);
  }

  loadAllProvider(event: Event) {
    this.auditService.writeOparationalLog('select provider#: All Providers');
    this.providerAll = this.providerConst;
    //console.log('loadallpro',this.providerAll);
    this.dashboardStoreService.providerBehaviour.next(this.providerAll);
    this.searchProvider.nativeElement.value = ''
    this.providerAll.map((item: any) => item.isChecked = false)
    this.showActive = true
  }

  providerSelected(event: any, provider: providerModel) {
    // console.log('=====provider=====', JSON.stringify(provider));
    this.auditService.writeOparationalLog('select provider#' + provider.providerid + "#" + provider.providerfirstname + ' ' + provider.providerlastname);
    this.dashboardStoreService.providerBehaviour.next([provider])
    this.providerAll.map((item: any) => item.isChecked = false)
    provider.isChecked = true
    this.showActive = false;
    this.appointService.triggerLoaderClick();
  }

  clear() {
    this.providerAll = this.providerConst;
    this.dashboardStoreService.providerBehaviour.next(this.providerAll);
    this.isNoResult = false
    this.allprov = true
  }

  apiErrorByHandler(error: any, api: string) {
    // console.log(`Error in API ${api}: `, error)
  }

  showNoResult() {
    this.isNoResult = true;
  }

  ngOnDestroy() {
    this.subscriptionSearch.unsubscribe();
    this.resetSearch();
  }

  notifyPatient() {
    this.appointService.triggerLoaderClick();
  }

  resetSearch(): void {
    if (this.searchProvider) {
      const searchInput = this.searchProvider.nativeElement.querySelector('input');
      if (searchInput) {
        searchInput.value = ''; // Clear the search input value
      }
    }
  }

}
