import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { IdleService } from 'src/app/core/services/idle-service.service';
import { WindowService } from 'src/app/core/services/window.service';

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'app-pre-registration-timeout-warning',
  templateUrl: './pre-registration-timeout-warning.component.html',
  styleUrls: ['./pre-registration-timeout-warning.component.scss'],
})
export class PreRegistrationTimeoutWarningComponent implements OnInit {

  counter: number = 31;
  subscribe: Subscription;

  constructor(public modalController: ModalController, private bsModalRef: BsModalRef, private idle: Idle,
    private router: Router, public windowService: WindowService, private idleService: IdleService) {


  }

  ngOnInit() {
    this.subscribe = this.idleService.counter.subscribe({
      next: (response: any) => {
        this.counter = this.counter - 1
      }
    })
  }


  reset() {
    this.idle.stop();
    this.idle.watch();
  }

  // hideChildModal(): void {
  //   this.bsModalRef.hide();
  // }

  stay() {
    this.bsModalRef.hide();
    this.idleService.resetSecond();
  }

  logout() {
    this.subscribe.unsubscribe()
    this.idleService.resetSecond();
    this.bsModalRef.hide();
    firebase
      .auth().signOut().then(() => {
        // this.presenceservice.signOut();
        this.bsModalRef.hide();
        sessionStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('type');
        // window.location.reload()
        this.router.navigate(['/clinic']);
      });
  }

  onClose() {
    // this.bsModalRef.hide();
  }

}
