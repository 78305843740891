<div class="clinic_dashboard_header_main d-flex justify-content-sb px-20 align-items-center borderBottom20">
  <div class="w-33">
    <div class="">
      <div class="text-ellipsis maxWidth500">
        <ion-text class="bodyM-txt-bold " color="gray100">{{tenantName}}</ion-text>
      </div>
      <div class="d-flex align-items-center hideOnPreRegister cursor" id="nav-popover-trigger2" *ngIf="!showHide">
        <!-- Map Icon -->
        <!-- <ion-icon class="bodyS-txt mr-3" src="../../../../../../assets/svg/map-pin 2.svg" color="highlight"></ion-icon> -->
        <ion-text class="bodyS-txt-italic mr-3" color="gray80">{{selectedLocation?.locationdescription}}</ion-text>
        <ion-icon src="../../../../../../assets/svg/chevron-down.svg" color="gray80" class=""></ion-icon>
        <ion-popover class="clinic_list_popup" trigger="nav-popover-trigger2" arrow="false" side="bottom"
          alignment="start" dismiss-on-select="true" show-backdrop="false" style="--offset-x: 0rem; --offset-y:0.5rem"
          arrow="false">
          <ng-template>
            <div class="clinic_location_list_main">
              <div class="clinic_location_list d-flex align-items-center borderBottom-20 px-10 cursor"
                *ngFor="let loc of location; let i=index" (click)="changeLocation($event,loc)">
                <div class="d-flex justify-content-sb w-100">
                  <div class="d-flex w-90">
                    <input type="checkbox" kendoCheckBox value="{{loc}}" [checked]="loc.isChecked" />
                    <kendo-label class="k-checkbox-label cursor"
                      [ngClass]="loc.isChecked == true ? 'bold-font-weight': ''"
                      text="{{loc.locationdescription}}"></kendo-label>
                  </div>
                  <!-- Map Icon -->
                  <ion-icon *ngIf="loc.isdefault" class="bodyM-txt mr-3 w-10 d-inline-block"
                    src="../../../../../../assets/svg/map-pin 2.svg" color="highlight"></ion-icon>
                </div>

              </div>
              <!-- <div class="clinic_location_list d-flex align-items-center borderBottom-20 px-10 cursor">
                <input type="checkbox" kendoCheckBox value="Westside Office" />
                <kendo-label class="k-checkbox-label cursor" text="Westside Office"></kendo-label>
              </div>
              <div class="clinic_location_list d-flex align-items-center px-10 cursor">
                <input type="checkbox" kendoCheckBox value="Market Village Associates" />
                <kendo-label class="k-checkbox-label cursor" text="Market Village Associates"></kendo-label>
              </div> -->
            </div>
          </ng-template>
        </ion-popover>
      </div>

    </div>

  </div>

  <div class="w-33 dashboard_name" [ngClass]="showHide == true ? 'w-50' : 'w-33'">
    <div class="text-center"><ion-text class="bodyL-txt-bold text-blue-color " *ngIf="!showHide && !title  && !usagetitle">Patient
        Self-Registration</ion-text></div>

    <div *ngIf="showHide && !title  && !usagetitle"><ion-text class="bodyL-txt-bold text-blue-color showOnPreRegister">Review Patient
        Information</ion-text></div>
    <!-- <div><ion-text *ngIf="title" class="bodyL-txt-bold text-blue-color showOnPreRegister">Audit Report</ion-text></div> -->
  </div>
  <div class="d-flex align-items-center justify-content-end widthAuto profile"
    [ngClass]="showHide == true ? 'w-50' : 'w-33'">
    <ion-icon class="title-32-font-size cursor mr-20" src="../../../../../../assets/svg/dashbaord-lock.svg"
      (click)="navigateToLogin()"></ion-icon>
    <ion-avatar class="default-avatar cursor" slot="start" (click)="popOver($event)">
      <img *ngIf="errorLoading" src="{{imgVal}}" />
      <span *ngIf="!errorLoading">{{image?.firstname | initials }}{{image?.lastname | initials}}</span>
    </ion-avatar>
  </div>
  <!-- <ion-popover class="signout_popup_main" arrow="false" trigger="signout-trigger" arrow="false" side="bottom" alignment="end" dismiss-on-select="true" show-backdrop="false" style="--offset-x: -1rem; --offset-y:0.3rem" >
    <ng-template>
     
      <div class="signout_popup ">
          routerLink="/practice-list" ->
         <div class="px-30 py-10 cursor borderBottomGray20" *ngIf="tenantLength.length>1" (click)="removetenant()">
          <ion-text class="bodyM-txt" color="gray80" >Change Pratice</ion-text>
        </div>
        <div (click)="logout()" class="cursor px-30 py-10">
          <ion-text class="bodyM-txt " color="gray80">Logout</ion-text>
        </div>
      </div> 
    </ng-template>
  </ion-popover> -->
</div>