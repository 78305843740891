import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-warning',
  templateUrl: './show-warning.component.html',
  styleUrls: ['./show-warning.component.scss'],
})
export class ShowWarningComponent implements OnInit {
  isButtonVisible: boolean = false;
  previousUrls: string[] = [];
  ifFromValidation: boolean = false;
  isInfoPage: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute,private location:Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrls.push(event.url);
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const paramValue = params['code'];
      this.ifFromValidation = (paramValue === '410') ? false : true;
    });
    this.isInfoPage = this.router.url.includes('info-page');
  }

  disableBackButton() {
    history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function () {
      history.pushState(null, document.title, window.location.href);
    });
  }

  goBack(): void {
    if (this.previousUrls.length > 1) {
      // Pop the last URL from the stack to get the URL before the current page
      this.previousUrls.pop();
      const previousUrl = this.previousUrls.pop();
      this.router.navigateByUrl(previousUrl);
    } else {
      // Redirect to default page if no previous URL is available
      this.router.navigate(['/']);
    }
  }
}
