<div class="clinic_practice_list_main main_container">
  <div class="clinic_practice_list_content">
    <div class="clinic_practice_list_heading px-10 py-20 borderBottomGray20 text-center">
      <ion-text class="h3-Montserrat" color="gray100">Practice List</ion-text>
    </div>
    <div class="clinic_practice_list px-10 py-20" *ngIf="practiceName.length">
      <div class="d-flex align-items-center mb-20 px-20" *ngIf="practiceName.length >= 11">
        <ion-searchbar placeholder="Search Practice" mode="q" [(ngModel)]="searchInput"></ion-searchbar>
      </div>
      <div class="pratice_list px-20" *ngIf="(practiceName | filterPractice: searchInput) as result">
        <div class="pratice_name mb-10 cursor d-flex align-items-center" [class.active]="practice.active"
          (click)="activePracticeList(practice)" *ngFor="let practice of result ; let index = index">
          <input type="radio" class="mr-5" kendoRadioButton [checked]="practice.active" hidden />
          <ion-text class="h5-regular" color="gray100">{{practice.tenantname}}</ion-text>

        </div>
        <!-- <div class="pratice_name mb-10 cursor" [class.active]="isActive"  (click)="activePracticeList()">
          <input type="radio" class="mr-5" kendoRadioButton [checked]="isActive" hidden/>
          <ion-text class="h5-regular" color="gray100">Practice name 2</ion-text>
        </div>
        <div class="pratice_name mb-10 cursor">
          <ion-text class="h5-regular" color="gray100">Practice name 3</ion-text>
        </div>
        <div class="pratice_name mb-10 cursor">
          <ion-text class="h5-regular" color="gray100">Practice name 4</ion-text>
        </div>
        <div class="pratice_name mb-10 cursor">
          <ion-text class="h5-regular" color="gray100">Practice name 5</ion-text>
        </div> -->
        <div class="no_patient_found mt-20" *ngIf="result.length === 0">
          <div class="no_patient_icon">
            <ion-icon src="../../../../assets/svg/search_orange.svg" class="ion-color-secondary100"
              color="secoundry100"></ion-icon>
          </div>
          <div class="text-center mt-10">
            <ion-text color="gray100" class="bodyS-txt-bold d-block mb-5" *ngIf="searchInput != ''">We couldn’t find any practice for
              search `{{searchInput}}` </ion-text>
            <ion-text class="bodyXS-txt" color="gray100">Check for spelling or try searching for another term</ion-text>
          </div>
        </div>
      </div>
    </div>
    <div class="clinic_practice_list_footer px-20 py-30 text-center borderTopGray20">
      <!-- routerLink="/clinic-dashboard" -->
      <ion-button fill="solid" color="white" class="filled-icon-btn" [disabled]='!isActive' (click)="connectToTenant()">
        <ion-text class="capitalize roboto">Connect</ion-text>
      </ion-button>
    </div>
  </div>
</div>
<app-idle></app-idle>
