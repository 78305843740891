<div class="patient_form_content">
  <!-- <div class="text-center w-100 p-10 borderBottom" *ngIf="!isDesktop">
    <ion-text class="h3-Montserrat text-blue-color">{{formname}}</ion-text>
  </div> -->
  <kendo-dialog-titlebar style="border-bottom: 1px solid #CCD4D8">
    <div class="d-flex w-100 align-items-center justify-content-sb ">
      <div class="text-center w-100">
        <ion-text class="h3-Montserrat tabletFont" color="gray100">{{formname}}</ion-text>
      </div>
      <ion-icon src="../../../../assets/svg/x.svg" color="gray80" class="h3 mr-10 cursor"
        (click)="hidePatientForm()"></ion-icon>
    </div>
  </kendo-dialog-titlebar>
  <form #patientForm='ngForm'>
    <div class="patient_form">
      <div class="card-body p-2" *ngIf="!isFormSummary">
        <div class="card m2" *ngFor="let jsons of submitJson; let x=index">
          <ng-container [ngSwitch]="jsons.type">
            <div *ngSwitchCase='"text"' class="pad-10">
              <div class="mb-15 px-30">

                <div class="d-flex text-card">
                  <div class="column pt-20">
                    <!-- <ion-text class="h4-M text-blue-color mb-5"><span *ngIf="jsons.required" class="required">* </span>
                      {{x + 1}}. {{jsons.title}}</ion-text> -->
                    <ion-text class="h4-M text-blue-color mb-5 d-flex">
                      <span *ngIf="jsons.required" class="required">* </span>
                      <span>{{x + 1}}.</span>
                      <span>{{jsons.title}}</span>
                    </ion-text>

                  </div>
                </div>

                <div *ngIf="jsons.questionInfo.ShowSubtitle && jsons?.subtitle != ''" class="d-flex text-card">
                  <label for="{{jsons.id}}" class="font-weight-light  pl-20">
                    {{jsons?.subtitle}}
                  </label>
                </div>

                <div *ngIf="jsons.questionInfo.Multiline;else singleLine">
                  <ion-textarea name="{{jsons.id}}" id="" cols="125" rows="3" placeholder="Type your answer here"
                    [attr.required]="jsons.required"></ion-textarea>
                </div>

                <ng-template class="mb-15 px-30 ml-10" #singleLine>
                  <input disabled type="text" name="{{jsons.id}}" [name]="jsons.qid" [class.dark-textarea]="isdisabledquestion"   class="text-answer  "
                    placeholder="Type your answer here"
                    [required]="jsons.required" [(ngModel)]="answerMap[jsons.id]" [ngModelOptions]="{standalone: true}">
                </ng-template>


              </div>
            </div>

            <!-- for choice**************************** -->
            <div *ngSwitchCase="'choice'" class="pad-10">
              <div class="mb-15 px-30">
                <div class="d-flex text-card">
                  <span class="d-inline-block" style="width: 6px;">
                  </span>
                  <div class="column pt-20">
                    <span class="d-inline-block" style="width: 6px;">

                    </span>
                    <ion-text class="h4-M text-blue-color mb-5 d-flex">
                      <span *ngIf="jsons.required" class="required">* </span>
                      <span>{{x + 1}}.</span>
                      <span>{{jsons.title}}</span>
                    </ion-text>
                    <!-- <ion-text class="bodyS-txt mb-5" color="gray100">Check any that are “YES”</ion-text> -->
                  </div>
                </div>

                <div *ngIf="jsons.questionInfo.ShowSubtitle && jsons?.subtitle != ''"
                  class="text-card ml-3 subTitle_preview">
                  <label for="{{jsons.id}}" class="font-weight-light pl-20">
                    {{jsons?.subtitle}}
                  </label>
                </div>
                <!-- for dropdown****************** -->
                <div class="text-card ml-10 subTitle_preview mb-2" *ngIf="jsons.questionInfo.dropdown">
                  <kendo-combobox [disabled]="isdisabledquestion" [class.dark-textarea]="isdisabledquestion"  class="w-50" placeholder="Select Your Answer"  [data]="jsons.questionInfo.Choices"
                 [name]="jsons.qid" [valuePrimitive]="true"
                    [(ngModel)]="answersDrop[jsons.id]" [ngModelOptions]="{standalone: true}"
                    [attr.required]="jsons.required" [textField]="'Description'" [valueField]="'Description'">
                  </kendo-combobox>
                </div>
                <!-- for radio********************* -->
                <div class=" ml-10 choice_option_preview" *ngIf="jsons.questionInfo.ChoiceType == 1">
                  <div *ngFor="let option of jsons.questionInfo.Choices; let x=index">
                    <div class="d-flex">                    
                      <div class="d-flex checkBoxContainer">
                        <label class="d-flex">
                        <input disabled type="radio" [name]="jsons.id" [value]="option.Description"
                           kendoRadioButton
                          (change)="showBoldText($event)" [checked]='isRadioansCheck(jsons.id,option.Description)'
                          [attr.required]="jsons.required" /> 
                        <!-- <div class="d-flex align-items-center"> -->
                          <ion-text class="ml-5" color="gray100"
                            [ngClass]="radiosaveanswer[jsons.id]==option.Description ? 'bodyM-txt-bold' : 'bodyM-txt'">{{option.Description}}</ion-text>
                        <!-- </div> -->
                      </label>
                      </div>                 
                    </div>
                  </div>
                  <div *ngIf="jsons.questionInfo.AllowOtherAnswer">
                    <div class="d-flex">
                      <label class=" d-flex col-md-6">
                        <input type="radio" name="{{jsons.type}}-{{jsons.id}}">
                        <input type="text" name="{{jsons.type}}-{{jsons.id}}" class="form-control ml-3 col-md-6"
                          [(ngModel)]="jsons.questionInfo.Description" placeholder="other">
                      </label>

                    </div>
                  </div>
                </div>
                <!-- for multiple choice -->
                <div class="p-2 ml-10 choice_option_preview" *ngIf="jsons.questionInfo.ChoiceType == 2">
                  <div *ngFor="let option of jsons.questionInfo.Choices; let x=index">
                    <div class="d-flex checkBoxContainer">
                      <label class="d-flex" >
                      <input disabled  type="checkbox" kendoCheckBox [required]="jsons.required"
                       
                        name="{{jsons.type}}-{{jsons.id}}" [checked]=isChecked(jsons.id,option.Description)
                        (change)="showBoldText($event)" />
                      <!-- <div class="d-flex align-items-center"> -->
                        <ion-text class="ml-5" color="gray100"
                          [ngClass]="isChecked(jsons.id,option.Description) ? 'bodyM-txt-bold' : 'bodyM-txt'">
                          {{option.Description}}</ion-text>
                      <!-- </div> -->
                    </label>
                    </div>
                  </div>
                  <div *ngIf="jsons.questionInfo.AllowOtherAnswer">
                    <div class="d-flex ">
                      <label for="{{jsons.type}}-{{jsons.id}}" class="form-group d-flex col-md-6">
                        <input type="checkbox" name="{{jsons.type}}-{{jsons.id}}">
                        <input type="text" name="{{jsons.type}}-{{jsons.id}}" class="form-control"
                          [(ngModel)]="jsons.questionInfo.Description" [ngModelOptions]="{standalone: true}"
                          placeholder="other">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- for date ************************* -->
            <div *ngSwitchCase="'date'" class="pad-10">
              <div class="mb-15 px-30">

                <div class="d-flex text-card">
                  <div class="column pt-20">
                    <span class="d-inline-block" style="width: 6px;">

                    </span>
                    <!-- <ion-text class="h4-M text-blue-color mb-5">
                      <span *ngIf="jsons.required" class="required">* </span>{{x + 1}}. {{jsons.title}}:</ion-text> -->
                    <ion-text class="h4-M text-blue-color mb-5 d-flex">
                      <span *ngIf="jsons.required" class="required">* </span>
                      <span>{{x + 1}}.</span>
                      <span>{{jsons.title}}</span>
                    </ion-text>
                  </div>
                </div>

                <div *ngIf="jsons.questionInfo.ShowSubtitle && jsons?.subtitle != ''" class="d-flex text-card">
                  <label for="{{jsons.id}}" class="font-weight-light  pl-20">
                    {{jsons?.subtitle}}
                  </label>
                </div>


                <div class="dateWidth">

                  <div class="d-flex">
                    
                  <kendo-dateinput  
                  [value]="jsons.dateAnswer" [attr.required]="jsons.required"
                  
                  (focus)="onDatepickerFocus()" placeholder="MM/DD/YY">
                  </kendo-dateinput>
                    <button disabled (click)="showCalendar(jsons.id)" class="dateInput-button k-input-solid">
                        <kendo-icon name="calendar" class=" k-input-solid"></kendo-icon>
                    </button>
                  </div>
                    <div class="calendar-button">
                      <kendo-calendar [disabled]="isdisabledquestion"  type="classic"
                       *ngIf="show[jsons.id]" [(ngModel)]="jsons.dateAnswer"
                       (valueChange)="dateSelect(jsons.id,jsons.dateAnswer)" 
                       [ngModelOptions]="{standalone: true}"
                       ></kendo-calendar>
                    </div>
<!-- 
                  <kendo-datepicker 
                  [value]="jsons.dateAnswer" [attr.required]="jsons.required"
                  (valueChange)="handledateanswer(jsons.id,$event)"
                  (focus)="onDatepickerFocus()" placeholder="MM/DD/YY" calendarType="classic" >
                  </kendo-datepicker> -->
               
                </div>
                

              </div>
            </div>

            <div *NgSwitchDefault>Uh oh! Somethings wrong</div>
          </ng-container>
        </div>
      </div>

      <!-- <div class="form_summary" *ngIf="isFormSummary">
        <div class="mb-10 px-30">
          <div class="column pt-20 ">
            <ion-text class="h4-M text-blue-color text-center">{{filledform}} of {{formCount}} Forms
              completed</ion-text>
            <ion-text class="bodyS-txt-italic mb-5" color="gray100">Press a form if you need to change something or
              press
              Done in you’re finished</ion-text>
          </div>
          <div>
            <div class="d-flex align-items-center form-icon-container" *ngFor="let item of formArray">
              <span class="form-icon-main">
                <ion-icon class="form-icon" src="../../../../assets/svg/consent.svg"></ion-icon>
                <span class="success-circle" *ngIf="isCompleted">
                  <ion-icon name="checkmark-outline" class=" ion-color-white" color=""></ion-icon>
                </span>
              </span>
              <ion-text class="h5-regular ml-15 cursor" color="highlight"
                (click)="toggleComplete()">{{item.title}}</ion-text>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </form>

  <kendo-dialog-actions style="border-top: 1px solid #CCD4D8;">
    <div class="w-100 patientFormStepper px-30 py-15">
      <!-- <kendo-stepper class="mb-10" [steps]="getSteps()" [(currentStep)]="formindex">
      </kendo-stepper> -->
      <!-- <div *ngIf="isDesktop">
        <div class="w-100 mt-15 text-center" *ngIf="isFormDone">
          <ion-button fill="solid" class="filled-icon-btn capitalize btn" (click)="doneform()">
            <ion-text color="white">Done</ion-text>
          </ion-button>
        </div>
      </div> -->
      <!-- <div *ngIf="!isDesktop">
        <div class="w-100 mt-15 text-center" *ngIf="isFormDone">
          <ion-button fill="solid" class="filled-icon-btn capitalize btn" routerLink="/app/info-focus">
            <ion-text color="white">Done</ion-text>
          </ion-button>
        </div>
      </div> -->
      <div class=" w-100 d-flex mt-15 align-items-center justify-content-sb">
        <!-- <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize btn" (click)="prev()">
          <ion-icon src="../../../../assets/svg/arrow-left.svg" color="gray100"></ion-icon>
          <ion-text class="ml-10">Prev</ion-text>
        </ion-button> 
        <ion-button fill="clear" class="filled-clear-btn capitalize btn" (click)="hideFormPopup()">
          <ion-text color="highlight" class="capitalize">skip</ion-text>
        </ion-button> 
         <ion-button fill="solid" class="filled-icon-btn capitalize btn" (click)="next()"
          [disabled]="patientForm.invalid">
          <ion-text color="white">Next</ion-text>
          <ion-icon src="../../../../assets/svg/arrow-right.svg" color="white" class="ml-10"></ion-icon>
        </ion-button> -->
        <!-- <ion-button *ngIf="!preRegState" fill="outline" color="highlight" class="filled-outline-btn capitalize btn" (click)="prev()">


          <ion-text>Cancel</ion-text>

        </ion-button> -->

        <!-- <ion-button fill="solid" class="filled-icon-btn capitalize btn" (click)="next()"
          [disabled]="!saveButtonDisable">
          <ion-text class="px-10" color="white">Save</ion-text>

        </ion-button> -->
      </div>
    </div>
  </kendo-dialog-actions>
</div>