import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientService } from 'src/app/core/services/patient.service';
type AnswerMap = { [qid: string]: string };

@Component({
  selector: 'clinic-dashboard-form-preview',
  templateUrl: './clinic-dashboard-form-preview.component.html',
  styleUrls: ['./clinic-dashboard-form-preview.component.scss'],
})
export class ClinicDashboardFormPreviewComponent  implements OnInit {
  formId: any;
  formname: any;
  submitJson: any[] = [];
  patientid: any;
  formsavedanswer: any[] = []
  updateState!: boolean
  userFormId: any[] = [];
  questiontype: any;
  choicetypeno: any;
  answerMap: AnswerMap = {};
  answersDrop: { [qid: string]: string } = {};
  checkBoxansTitle: any[] = []
  finalCheckArray: any[] = []
  checkboxStates: any[] = []
  radiosaveanswer: any[] = []
  isBold: boolean = false;
  isDigitalSign: boolean = true;
  isFormSummary: boolean = false;
  formindex: any;
  referenceid: any;
  tenantid: any
  isdisabledquestion!: boolean;
  isPatientFormOpened!: boolean
  constructor(public patientService: PatientService, private commonService: CommonService) { }

  ngOnInit() { this.isFormSummary = false;
    this.isdisabledquestion = true
    this.patientService.previewForm.subscribe((res) => {
      if (res) {
        this.formId = res
      }

    })
    this.patientid = this.commonService.getSessionStorage('pid');
    if (this.patientid == "") {
      this.patientid = this.commonService.getSessionStorage('patientid')
    }
    this.referenceid = this.commonService.getSessionStorage('sid')
    if (this.referenceid == '' || this.referenceid == null) {
      this.referenceid = this.commonService.getSessionStorage('sessionid')
    }
    this.tenantid = this.commonService.getSessionStorage('tid')
    this.getnewforms()
  }
  getnewforms() {
    this.patientService.getNewForm(this.formId).subscribe({
      next: (response: any) => {
        let newformdata = response.questionsmodel;
        this.formname = response.title;
        this.submitJson = newformdata;
        this.submitJson.forEach(item => {

          item.questionInfo = JSON.parse(item.questionInfo)
        });
      },
      error: (err: any) => {
      },
      complete: () => {

        this.patientService.getUserForm(this.patientid, this.formId, this.referenceid).subscribe(res => {
          this.formsavedanswer = res;
          if (this.formsavedanswer.length > 0) {
            this.updateState = true
            this.userFormId = res[0].userform_id
            // this.checkButtonValidation();
            // this.checkValidationUpdate()
          }

          this.formsavedanswer.forEach(element => {
            this.submitJson.forEach(item => {
              if (item.id == element.formquestionid) {
                this.questiontype = item.type;
                if (item.type == "choice") {
                  this.choicetypeno = item.questionInfo.ChoiceType;
                }
              }
            })

            element.answer = JSON.parse(element.answer)
            if (this.questiontype == 'text') {
              this.answerMap[element.formquestionid] = element.answer;
            } else if (this.questiontype == 'choice') {
              if (this.choicetypeno == 3) {
                this.answersDrop[element.formquestionid] = element.answer;
              } else if (this.choicetypeno == 2) {
                this.checkBoxansTitle = element.answer
                this.checkBoxansTitle.forEach((x: any) => {
                  if (x.isSelected) {
                    this.finalCheckArray.push(x.anstitle);
                    this.checkboxStates[element.formquestionid] = this.finalCheckArray;
                  }


                })
              } else if (this.choicetypeno == 1) {
                this.radiosaveanswer[element.formquestionid] = element.answer;

                // let choiceans = element
                // this.submitJson.forEach(x => {
                //   if (x.id == element.formquestionid) {
                //     x.questionInfo.Choices.map((choice: { Description: string; ischecked: boolean }) => {                        
                //       const result= x.questionInfo.Choices.some((xy:any) => xy.Description === choiceans.answer);                        
                //       if ( choice.Description === choiceans.answer) {
                //         choice.ischecked = result
                //       }
                //                      });
                //   }
                // })
              }
            } else if (this.questiontype == 'date') {
              this.submitJson.forEach(x => {
                if (x.id == element.formquestionid) {
                  x.dateAnswer = new Date(element.answer);
                }
              })
            }
          });
          // this.checkButtonValidation()
        })

      }
    })

  }
  isRadioansCheck(qid: any, option: any) {
    const ispresent = this.radiosaveanswer[qid]
    return ispresent ? ispresent.includes(option) : false

  }
  showBoldText(e: any) {
    if (e.target.checked) {

      this.isBold = true;
      this.isDigitalSign = false;
    } else {
      this.isBold = false;
      this.isDigitalSign = true;
    }
  }
  isChecked(qid: any, option: any) {
    const statesForId = this.checkboxStates[qid];
    return statesForId ? statesForId.includes(option) : false;
  }
  onDatepickerFocus() {

    const datePicker = document.querySelector('kendo-datepicker');
    if (datePicker) {
      datePicker.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    }
  }

  dateSelect(id: any, date: any) {
    this.show[id] = !this.show[id]

  }
  show: { [key: number]: boolean } = {};
  showCalendar(id: any) {
    this.show[id] = !this.show[id]
  }
  hidePatientForm() {
    this.patientService.closepopup.next(false)
    this.isPatientFormOpened = false;
  }

}
