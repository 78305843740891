import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slash'
})
export class SlashPipe implements PipeTransform {


  transform(value: string, ...args: unknown[]): string  {
    if(value !== null) {
    if(value.indexOf("|") !== -1 ) {
      return value.split("|").join(" ")
    }else {
      return value
    }
    }
    else
      return value;
  }

}
