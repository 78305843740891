<div class="warningScreen_main">
  <div class="warningScreen_content">
    <ion-icon *ngIf="!isInfoPage" src="../../../../assets/svg/alert-triangle.svg" class="warning_icon d-block mb-30"></ion-icon>
    <ion-icon  *ngIf="isInfoPage" src="../../../../assets/svg/info_circle.svg" class="warning_icon d-block mb-20"></ion-icon>
    <div class="error-content">
      <ion-text class="bodyM-txt mb-10 sub-error" color="gray100"  *ngIf="!ifFromValidation && !isInfoPage">
        The URL provided is invalid or has expired.
      </ion-text>
      <ion-text class="bodyM-txt mb-10 sub-error" color="gray100"  *ngIf="ifFromValidation && !isInfoPage">
        Sorry, navigating back is not permitted.
      </ion-text>
      <ion-text class="bodyM-txt mb-6 sub-error" color="gray100"   *ngIf="isInfoPage">
        You have successfully completed pre-registration process.
      </ion-text>
      <ion-text class="body-txt-18 mb-20" color="gray100" >
       Please contact your practice for further assistance.
      </ion-text>
    </div>

    <!-- <div class="error-content" *ngIf="ifFromValidation">
      <ion-text class="bodyM-txt mb-6 sub-error" color="gray100">
        Sorry, navigating back is not permitted.
      </ion-text>
      <ion-text class="body-txt-18 mb-20" color="gray100">
        Please contact your practice for further assistance.
      </ion-text>
    </div> -->
<!-- 
    <div class="error-content" *ngIf="ifFromValidation">
      <ion-text class="bodyM-txt mb-6 sub-error" color="gray100">
        You have successfully completed pre-registration process.
      </ion-text>
      <ion-text class="body-txt-18 mb-20" color="gray100">
        Please contact your practice for further assistance.
      </ion-text>
    </div> -->
      <!-- <ion-text class="bodyL-txt-large  mb-20" color="gray100" *ngIf="ifFromValidation">
        Sorry, navigating back is not permitted. Please contact your practice for further assistance.
      </ion-text> -->
    <!-- <div class="mt-25">
      <ion-button fill="solid" color="white" class="filled-btn warning_btn" (click)="goBack()" >
        <ion-text class="h5 roboto text-uppercase" >Back</ion-text>
      </ion-button>
    </div> -->
  </div>
</div>