import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/core/tablet-service/login.service';

@Component({
  selector: 'app-validate-url',
  templateUrl: './validate-url.component.html',
  styleUrls: ['./validate-url.component.scss'],
})
export class ValidateURLComponent implements OnInit {
  referenceId: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private loginService: LoginService) {
    // this.disableBackButton();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.referenceId = params['refid'];
      this.validateURL();
    });
  }

  validateURL() {
    this.loginService.validatePatientURL(this.referenceId).subscribe((response: any) => {
      if (response && response.id) {
        this.router.navigate(['login/', response.id]);
      } else {
        this.router.navigate(['error'], { queryParams: { code: '410' } });
      }
    },
      (error) => {
        this.router.navigate(['error'], { queryParams: { code: '410' } });
      }
    );
  }

  disableBackButton() {
    history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function () {
      history.pushState(null, document.title, window.location.href);
    });
  }

}
