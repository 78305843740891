import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { DashboardStoreService } from '../services/dashboard-store.service';

@Injectable({
  providedIn: 'root'
})
export class PreRegisterPatientResolver implements Resolve<any> {

  patientId: string = '';
  sessionId: string = '';
  constructor(
    private service: DashboardStoreService,
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.patientId = route.params['id']
    this.sessionId = route.params['sessionId']
    return forkJoin({
      patient: this.service.getNewPatientDetails(this.patientId, this.sessionId),
      insurance: this.service.getNewInsuranceDetails(this.patientId, this.sessionId),
      patientInfo: this.service.getPatientDetails(this.patientId),
      pharmacy: this.service.getPatientPharmacyDetails(this.sessionId, this.patientId),
      pcp:this.service.getPCPDetails(this.patientId,this.sessionId),
      referral: this.service.getReferrerDetails(this.patientId,this.sessionId)
    })
  }
}
