import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { take } from 'rxjs';
import { DashboardLocationModel } from 'src/app/core/models/dashboardLocationModel';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { environment } from 'src/environments/environment';
import { LogoutAvatarComponent } from './logout-avatar/logout-avatar.component';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';

@Component({
  selector: 'app-clinic-dashboard-header',
  templateUrl: './clinic-dashboard-header.component.html',
  styleUrls: ['./clinic-dashboard-header.component.scss'],
})
export class ClinicDashboardHeaderComponent implements OnInit {

  location: Array<any> = [];
  selectedLocation: DashboardLocationModel = {
    locationid: "",
    locationdescription: "",
    city: "",
    state: "",
    zip: "",
    addressline1: "",
    addressline2: "",
    isblocked: false,
    tenantid: "",
    isdefault: false,
    isChecked: false
  };
  image: any = {
    username: "",
    url: "",
    firstname: "",
    lastname: ""
  }
  imgVal: string = '';
  errorLoading: boolean = false;
  tenantLength: any;
  showHide: boolean = false;
  currentRoute: string = '';
  @Input() public title: string;
  @Input('usageTitle') usagetitle:any;
  
  constructor(
    private loginService: LoginService,
    private patientService: PatientService,
    private dashboardService: DashboardStoreService,
    private router: Router,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private popOverCtrl: PopoverController,
    private auditService: AuditService
  ) {
    this.location = [];
  }

  tenantName: string = '';
  ngOnInit() {
    let urlResponse = '';
    this.activatedRoute.url.subscribe({
      next: (response: any) => {
        if (response[0]?.path == 'clinic-dashboard')
          this.showHide = false
        else
          this.showHide = true
      }
    })
    this.activatedRoute.params.subscribe(() => {
      this.getTenantIds();
    });
    this.getTenants()
    this.activatedRoute.url.subscribe({
      next: (response: any) => {
        this.currentRoute = response.map((item: any) => item.path).join('/')
      }
    })
    this.tenantName = this.commonService.getSessionStorage('TN')
    // this.getTenantIds();
  }


  getTenants() {
    var piDetails = this.commonService.getSessionStorage('PI');
    var token = piDetails.token;
    var uid = this.commonService.getSessionStorage('UID');
    this.loginService.getQoreUserDetails(uid, token).subscribe((response: any) => {
      if (response) {
        this.tenantLength = response.tenants
      }
    });

  }


  getTenantIds() {
    //let tenant = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('tenant'))));

    let tenant = this.commonService.getSessionStorage('tnt');
    // console.log('tennathead', tenant);
    if (tenant != null) {
      //let imageUrl = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('url'))));
      let imageUrl = this.commonService.getSessionStorage('url');
      this.image = imageUrl;
      this.loadImage(this.image.url);
      let alltenant: Array<string> = []
      if (tenant != '')
        // tenant.map((item: any) => alltenant.push(item.tenantid))
        //console.log('tenantid',tenant);
        // tenant.map((item: any) => alltenant.push(item.tenantid))
        // this.dashboardService.getLocationByTenantId(alltenant.toString()).subscribe({
        //   next: this.getLocationResponseByHandler.bind(this)
        this.dashboardService.getLocationByTenantId(tenant).subscribe({
          next: this.getLocationResponseByHandler.bind(this)
        })
    } else {
      setTimeout(() => this.getTenantIds(), 10)
    }
  }

  getLocationResponseByHandler(object: DashboardLocationModel[]) {
    this.location = object;
    this.filterEnabledLocations();
    this.tenantName = this.commonService.getSessionStorage('TN')
    // if (this.commonService.getLocalStorage('location') && this.commonService.getLocalStorage('location')?.locationid && object.length && object.some((item: DashboardLocationModel) => item.locationid === this.commonService.getLocalStorage('location')?.locationid)) {
    //   object.map((item: DashboardLocationModel) => {
    //     if (item.locationid === this.commonService.getLocalStorage('location').locationid) {
    //       item.isChecked = true
    //       this.selectedLocation = item;
    //     }
    //   })
    // }
    // else {
    //   this.selectedLocation =object.filter((item: DashboardLocationModel) => item.isdefault)[0]
    //   object.map((item: DashboardLocationModel) => { if (item.isdefault) { item.isChecked = true } })
    // }
    // this.dashboardService.locationBehaviour.next([this.selectedLocation])
  }

  filterEnabledLocations() {
    const tenant = this.commonService.getSessionStorage('tnt');
    this.dashboardService.getEnabledLocationByTenantId(tenant).subscribe((enabledLocations: any[]) => {
      
      // Filter locations based on enabled locations
      this.location = this.location.filter(loc => 
        enabledLocations?.some(enabledLoc => enabledLoc.locationid === loc.locationid)
      );
  
      // Verify stored location
      this.verifyStoredLocation();
      
      // If there is a valid stored location in localStorage
      const storedLocation = this.commonService.getLocalStorage('location');
      if (storedLocation && storedLocation.locationid && this.location.length && 
          this.location.some(item => item.locationid === storedLocation.locationid)) {
        this.location.forEach(item => {
          if (item.locationid === storedLocation.locationid) {
            item.isChecked = true;
            this.selectedLocation = item;
          }
        });
      } else {
        // Select the default location or the first item if no default is available
        this.selectedLocation = this.location.find(item => item.isdefault) || this.location[0];
        
        if (this.selectedLocation) {
          this.selectedLocation.isChecked = true;
        }
      }
  
      this.dashboardService.locationBehaviour.next([this.selectedLocation]);
    });
  }
  
  // filterEnabledLocations() {
  //   const tenant = this.commonService.getSessionStorage('tnt');
  //   this.dashboardService.getEnabledLocationByTenantId(tenant).subscribe((enabledLocations: any[]) => {
  //     console.log('Enabled locations:', enabledLocations);
  //     this.location = this.location.filter(loc => enabledLocations.some(enabledLoc => enabledLoc.locationid === loc.locationid));
  //     console.log("this.location", this.location)
  //    // this.selectedLocation = this.location.filter((item: DashboardLocationModel) => item.isdefault)[0];
  //    this.verifyStoredLocation()
  //     if (this.selectedLocation) {
  //       this.selectedLocation.isChecked = true;
  //     } else if (this.location.length > 0) {
  //       this.selectedLocation = this.location[0];
  //       this.selectedLocation.isChecked = true;
  //     }
  //     this.dashboardService.locationBehaviour.next([this.selectedLocation])
  //   });

  // }

  verifyStoredLocation() {
    const storedLocation = this.commonService.getLocalStorage('location');
  
    if (storedLocation && storedLocation.locationid) {
      const exists = this.location.some(loc => loc.locationid === storedLocation.locationid);
      if (exists) {
        this.selectedLocation = this.location.find(loc => loc.locationid === storedLocation.locationid);
        this.selectedLocation.isChecked = true;
      } else {
        localStorage.removeItem('location');
        this.selectedLocation = null;
      }
    }
    // Check if no selected location is set, then select the first item
    if (!this.selectedLocation && this.location.length > 0) {
      this.selectedLocation = this.location[0];
      this.selectedLocation.isChecked = true;
      // Update the local storage with the new selected location
      this.commonService.setLocalStorage('location', this.selectedLocation);
    }
  }
  
  
  changeLocation(event: any, locationSel: DashboardLocationModel) {
    // this.location = 
    this.commonService.setLocalStorage('location', locationSel)
    this.location.forEach((item: DashboardLocationModel) => item.isChecked = false)
    locationSel.isChecked = true
    this.selectedLocation = locationSel

    //console.log('locationSel', locationSel);
    this.auditService.writeOparationalLog('change location#' + locationSel.locationid + "#" + locationSel.locationdescription);
    this.dashboardService.locationBehaviour.next([locationSel])
  }

  loadImage(urls: string) {
    let url = environment.otc_url + 'getSignUrl?medialink=' + urls
    this.dashboardService.getImage(url).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response && response.base64Content) {
          if (response.base64Content != "") {
            this.imgVal = 'data:image/png;base64, ' + response.base64Content
            this.errorLoading = true;
          }
          else
            this.errorLoading = false;
        }
      },
      error: (error: any) => {
        this.errorLoading = false;
      }
    })
  }

  navigateToLogin() {
    // sessionStorage.removeItem("returnLocation")
    this.auditService.writeOparationalLog('LockScreen#' + 'ClinicDashboardHeaderComponent');
    sessionStorage.removeItem('PI');
    sessionStorage.removeItem('url');
    sessionStorage.removeItem('UID');
    sessionStorage.removeItem('sid');
    sessionStorage.removeItem('uifo');
    sessionStorage.removeItem('tid');
    sessionStorage.removeItem('pid');
    sessionStorage.removeItem('isVerified');
    //sessionStorage.clear()
    // console.log(this.currentRoute)
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    let returnLoc = JSON.stringify({ loc: this.currentRoute, check: true })
    this.commonService.setSessionStorage("returnLocation", returnLoc)
    this.router.navigate(['/clinic'])
  }

  logout() {
    this.loginService.logout()
    sessionStorage.removeItem('returnLocation')
    // sessionStorage.clear()
    // this.router.navigate(['/clinic'])
  }
  removetenant() {
    //sessionStorage.removeItem('tenant')
    this.location = [];
    this.dashboardService.locationBehaviour.next(this.location)
    this.router.navigate(['/practice-list']);
  }

  async popOver(event: any) {
    const popOvers = await this.popOverCtrl.create({
      component: LogoutAvatarComponent,
      event: event,
      cssClass: 'custom-popover',
      componentProps: { tenant: this.tenantLength }
    })

    await popOvers.present();
  }

}
