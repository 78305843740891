<!-- <div class="warningScreen_main">
  <div class="warningScreen_content">
    <div class="error-content">
      <ion-text class="body-txt-18 mb-20" color="gray100">
        You don't have permissions to access this module!
      </ion-text><br><br><br>
      <div *ngIf="!viewDashboard">
        <ion-button routerLink="/clinic" fill="solid" color="white" class="filled-icon-btn mt-3">
          <ion-text class="capitalize roboto">Go To Login</ion-text>

        </ion-button>
      </div>
      <div *ngIf="viewDashboard && !showReviewButton">
        <ion-button (click)="navigate()" fill="solid" color="white" class="filled-icon-btn mt-3">
          <ion-text class="capitalize roboto">Go Back</ion-text>
        </ion-button>
      </div>

    </div>

  </div>
</div> -->



<!-- Permission error Design starts -->

<div class="warningScreen_main">
  <div class="center-data d-flex align-items-center">

    <div class="permission-error-img mr-50">
      <ion-img src="../../../assets/svg/Permission_error_svg.svg"></ion-img>
    </div>

    <div class="text-center">
      <div class="error-data d-grid align-items-center text-center">
        <div class="error-text d-grid text-center mb-20">
          <ion-text class="ion-color-primary100 error-text">401</ion-text>
          <ion-text class="ion-color-primary100 unauthorized">Unauthorized</ion-text>
        </div>
        <ion-text class="font20-regular subtext" color="gray60">You are not authorized to access this page.</ion-text>
       </div>

       <div *ngIf="!viewDashboard">
        <ion-button routerLink="/clinic" fill="solid" color="white" class="filled-icon-btn  mt-20">
          <ion-text class="capitalize roboto">Go To Login</ion-text>

        </ion-button>
      </div>

       <div *ngIf="viewDashboard && !showReviewButton">
        <ion-button (click)="navigate()" fill="solid" color="white" class="filled-icon-btn  mt-20">
          <ion-text class="capitalize roboto">Take me back</ion-text>
        </ion-button>
      </div>
      
    </div>
  </div>
</div>

<!-- Permission error Design ends -->
