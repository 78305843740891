import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { WindowService } from 'src/app/core/services/window.service';
import { PracticeService } from 'src/app/services/practice.service';

@Component({
  selector: 'app-logout-messages',
  templateUrl: './logout-messages.component.html',
  styleUrls: ['./logout-messages.component.scss'],
})
export class LogoutMessagesComponent implements OnInit {
  
  logout: any;
  session: any;
  practiceData:any;
  getPhoneNumber:any;

  constructor(public windowService: WindowService, private route: ActivatedRoute,public practiceService:PracticeService,
    private commonService: CommonService) { }

  public opened = false;

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
  ngOnInit() {
      this.route.queryParams.subscribe((queryParamMap) => {
      this.logout = queryParamMap['islogout'];
      this.session = queryParamMap['issessionclear'];
    });
    const TenantId =  this.commonService.getSessionStorage('tid');
    this.getPracticeDetails(TenantId);
  }
  editInfoOpened: boolean = false;
  isInfo: boolean = false;
  openInfoPopup() {
    this.editInfoOpened = true;
    this.isInfo = true;
  }


    //function for to get the patient practice details
    getPracticeDetails(TenantId: any) {
      this.practiceService.getPracticeDetailsByTenantId(TenantId).subscribe((res: any) => {
        if (res) {
          this.practiceData = res;
          const getMobile = this.practiceData?.tenantOrganization[0]?.telecom.workphone;
          this.getPhoneNumber = this.formatPhoneNumber(getMobile);
        }
      });
    }
  
    formatPhoneNumber(getPhoneNumber: any) {
      const digitsOnly = getPhoneNumber.replace(/\D/g, '');
      const formattedNumber = `(${digitsOnly.slice(0, 3)})-${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
      return formattedNumber;
    }
}
