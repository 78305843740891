<ion-header class="main_container">
  <div *ngIf="isDesktop" class="ion-header-bg-color mid-container header">
   <div class="row w-100 text-center mid-header-section px-30 pt-20 align-items-center">
     <div class="w-100 text-center ">
       <div class="column">
       <ion-text color="gray80" class="bodyL-txt-large pb-3 ion-text-nowrap"><span class="title">{{ practiceData?.tenantname}}</span></ion-text>
       <!-- <ion-text color="gray80" class="bodyS-txt pt-3">Our tagline is the best tagline for you</ion-text> -->
       </div>
      <!-- <div class="pt-75 d-flex justify-content-start">
         <ion-text class="title-32-font-size text-blue-color">Welcome, {{ patientName }}</ion-text> 
     </div> -->
       </div>
       <div class=" ion-align-items-right w-50 d-flex justify-content-end banner">
         <ion-icon src="../../../../assets/svg/Banner Image.svg" class="ml-5 main-icon"></ion-icon>
       </div>
     </div>
     </div>
     
     <div  class="ion-header-bg-color headermobile" *ngIf="!isDesktop">
     <div class="text-center">
       <ion-text color="gray80" class="bodyL-txt-large">{{ practiceData?.tenantname}}</ion-text>
     </div>
   </div>
   </ion-header>