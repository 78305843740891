import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  getTenantClearGageCredentials(tenantid: any) {
    let url = (environment.pebackend_url + 'cleargageconfig/getcleargagedetailsbytenantid/' + tenantid);
    return this.http.get(url);
  }

  makePayment(data:any) {
    let url = (environment.pebackend_url + 'cleargagetransaction/makepayment');
    return this.http.post(url, data);
  }

  saveTransactionDetails(TransactionDetailsobject: any){
    let url = (environment.pebackend_url + 'cleargagetransaction/addtransactiondetails');
    return this.http.post(url,TransactionDetailsobject);
  }

}
