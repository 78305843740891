<div class="error-screen-main">
  <div class="error-screen-content">
    <!-- <ion-icon src="../../../../assets/svg/alert-triangle.svg" class="warning_icon d-block mb-30"></ion-icon> -->
    <ion-icon src="../../../../assets/svg/page-not-found.svg"
      class="page-not-found-icon d-block mb-20 mr-40"></ion-icon>
    <div class="page-error">
      <div class="error-content">
        <ion-text class="mb-10 sub-error" color="primary100">
          404
        </ion-text>
        <div class="text-error">
          <ion-text class="subtext-error mb-20" color="gray60">
            Page not found
          </ion-text>

          <ion-text class="h5-regular mb-20" color="gray60">
            Sorry, the page you are looking for doesn't exist or has been moved
          </ion-text>
        </div>
      </div>


      <!-- <div class="mt-25">
        <ion-button fill="solid" color="white" class="filled-btn warning_btn">
          <ion-text class="h5 roboto text-uppercase">Back</ion-text>
        </ion-button>
      </div> -->
    </div>
  </div>
</div>