import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clinic-warning',
  templateUrl: './clinic-warning.component.html',
  styleUrls: ['./clinic-warning.component.scss'],
})
export class ClinicWarningComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
