import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value != null && value != "")
    return value[0].toUpperCase();
    else
    return ""
  }

}
