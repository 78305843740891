import { Injectable } from '@angular/core';
import firebase from "firebase/compat/app";
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import 'firebase/compat/auth';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  token: any;
  gettoken: any;

  constructor(private http: HttpClient,
    private handler: HttpBackend,
    private _router: Router,
    private commonService: CommonService
  ) { }


  doLogin(value: { email: string; password: string; }) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            //this.getIP();
            let user = firebase.auth().currentUser;

            if (user?.emailVerified) {
              resolve(res);
            }
            else {
              firebase.auth().signOut();
            }
          },
          err => reject(err)
        );
    });
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      let user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }

  getQintakeUserDetails(userId: string, token: string) {
    let httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
    return this.http.get(environment.auth_url + "/users/" + userId, httpOptions)
  }

  getQoreImage(uid: string, token: string) {
    let httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
    return this.http.get(environment.auth_url + "/users/" + uid, httpOptions)
  }


  getAccessUserDetails(id: string, token: any) {
    this.http = new HttpClient(this.handler);
    let strServiceURL = environment.auth_url + "/cicp/accessUser/" + id;
    return this.http.get<any>(strServiceURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  public get_session(key: any) {
    return localStorage.getItem(key);
  }

  public group(key: any) {
    return localStorage.getItem(key);
  }

  getQoreUserDetails(cicpuserid: any, token: any) {
    let httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
    return this.http.get<any>(environment.auth_url + "/cicp/authorizeUserForApp/" + cicpuserid + '/4', httpOptions);
  }

  logout() {
    firebase.auth().signOut().then(() => {
      // this.presenceservice.signOut();
      sessionStorage.clear();
      localStorage.removeItem('user');
      localStorage.removeItem('type');
      // window.location.reload()
      this._router.navigate(['/clinic']);
    });
  }

  permissionError() {
    firebase.auth().signOut().then(() => {
      // this.presenceservice.signOut();
      sessionStorage.clear();
      localStorage.removeItem('user');
      localStorage.removeItem('type');
      // window.location.reload()
      this._router.navigate(['/permission-error']);
    });
  }

  reviewPatientPermission(){
    this._router.navigate(['/permission-error']);
  }

  //verify dob
  getPatientDob(patientId: any, patientDob: any): Observable<any> {
    this.token = this.commonService.getSessionStorage('PI')
    this.gettoken = this.token
    const usetoken = this.gettoken.token
    let httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + usetoken })
    };

    return this.http.get<any>(environment.qintake_url + "/clinic/verifyPatient" + "?" + "id" + "=" + patientId + "&" + "dob" + "=" + patientDob, httpOptions);
  }

  validatePatientURL(refId: any) {
    return this.http.get<any>(environment.qintake_url + "/validateURL/" + refId);
  }
}