<div class="pre-registration-patient-main main_container">
    <app-clinic-dashboard-header></app-clinic-dashboard-header>
    <div class="d-flex justify-content-sb px-15 py-10">
        <div class="d-flex">
            <div>
                <ion-avatar class="small-avatar" slot="start">
                    {{patientInfo.firstname | initials}}{{patientInfo.lastname | initials}}
                </ion-avatar>
            </div>
            <div>
                <div class="line-13">
                    <ion-text class="font16 text-blue-color" color="text-blue-color">
                        <b>{{patientInfo.lastname | uppercase }},</b> {{patientInfo.firstname | titlecase}}
                        <span *ngIf="!(patientInfo.preferredname == null || patientInfo.preferredname == '')">
                            "{{patientInfo.preferredname | titlecase}}"

                        </span>

                        <span *ngIf="!(patientInfo.middlename == null || patientInfo.middlename == '')">
                            {{patientInfo.middlename| initials}}.

                        </span> </ion-text>
                </div>
                <div class="line-15">
                    <ion-text class="font13-italic" color="gray80">{{patientInfo.dob | calculateAge}}y
                        {{patientInfo.gender | initials}} </ion-text>
                    <ion-text class="bodyXS-txt" color="gray20">|</ion-text>
                    <ion-text class="font13-italic" color="gray80"> {{patientInfo.dob | dateFormat}}
                    </ion-text>
                </div>
            </div>

        </div>
        <div><ion-text class="bodyS-txt text-HLblue-color pointer" (click)="showUpdated()">{{showText}}</ion-text>
        </div>

    </div>
    <div class="pre-registration-patient  px-15 py-10 mb-20 pb-50">
        <div class="grid-col-3 p-10 pre-registration-top-row bg-lightBlue border-darkBlue border-bottomNone ">
            <div>
                <input type="checkbox" kendoCheckBox (click)="checkAll()" [checked]="markChecked" />
                <ion-text class="bodyS-txt ml-10" color="black">Select All</ion-text>
            </div>
            <div class="">
                <ion-text class="bodyS-txt-bold uppercase" color="black">new</ion-text>
            </div>
            <div class="">
                <ion-text class="bodyS-txt-bold uppercase" color="black">current</ion-text>
            </div>
        </div>
        <div class="mb-15">
            <div class="border-darkBlue  mb-15">
                <div>
                    <div *ngIf="!isUpdated ?  true: (calulatePatient > 0) ? true : false">
                        <div class="p-10 bg-lightBlue2">
                            <ion-text class="h5" color="gray80">Patient Details</ion-text>
                        </div>
                        <ng-container *ngFor="let initialKey of patientDetail">
                            <div class="grid-col-3 "
                                [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}"
                                [ngClass]="{'borderBottomGray05': !isUpdated ?  true : !(initialKey.values_match) ? true: false}">
                                <ng-container *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false">

                                    <div class="d-flex align-items-center">
                                        <div>
                                            <input type="checkbox" kendoCheckBox #checked [checked]="markChecked"
                                                (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                                *ngIf="!(initialKey.values_match)" />
                                        </div>
                                        <ion-text class="bodyS-txt"
                                            [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                            color="primary100">{{initialKey.attribute}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt" color="gray80"
                                            [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt"
                                            color="gray80">{{initialKey.existing_value}}</ion-text>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>


                    <div *ngIf="!isUpdated ?  true: (calulateEmergency > 0) ? true : false">
                        <div class="p-10 bg-lightBlue2">
                            <ion-text class="h5" color="gray80">Emergency Contact</ion-text>
                        </div>
                        <ng-container *ngFor="let initialKey of patientEmergency">
                            <div class="grid-col-3 "
                                [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}"
                                [ngClass]="{'borderBottomGray05': !isUpdated ?  true : !(initialKey.values_match) ? true: false}">
                                <ng-container *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false ">

                                    <div class="d-flex align-items-center">
                                        <div>
                                            <input type="checkbox" kendoCheckBox #checked
                                                (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                                [checked]="markChecked" *ngIf="!(initialKey.values_match)" />
                                        </div>
                                        <ion-text class="bodyS-txt"
                                            [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                            color="primary100">{{initialKey.attribute | titlecase}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt" color="gray80"
                                            [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt"
                                            color="gray80">{{initialKey.existing_value}}</ion-text>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="!isUpdated ?  true: (calculatePCP  || pharmacyName || calculatereferral  ) ? true : false">
                        <!-- || (calculatereferral > 0) -->
                        <div class="p-10 bg-lightBlue2">
                            <ion-text class="h5" color="gray80">Other Details</ion-text>
                        </div>
                        <ng-container *ngFor="let initialKey of pcpDetails">
                            <div class="grid-col-3 "
                                [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}"
                                [ngClass]="{'borderBottomGray05': !isUpdated ?  true: !(initialKey.values_match) ? true: false}">
                                <ng-container *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false ">

                                    <div class="d-flex align-items-center">
                                        <div>
                                            <input type="checkbox" kendoCheckBox #checked
                                                (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                                [checked]="markChecked" *ngIf="!(initialKey.values_match)" />
                                        </div>
                                        <ion-text class="bodyS-txt"
                                            [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                            color="primary100">{{initialKey.attribute | titlecase}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt" color="gray80"
                                            [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt"
                                            color="gray80">{{initialKey.existing_value}}</ion-text>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isUpdated ?  true: pharmacyName?.values_match == false ? true:false">
                            
                            <div class="grid-col-3 " [ngStyle]="{'padding': !isUpdated ?  '10px ': !(pharmacyName?.values_match) ? '10px': ''}"
                                [ngClass]="{'borderBottomGray05': !isUpdated ?  true: !(pharmacyName?.values_match) ? true: false}">

                                    <div class="d-flex align-items-center">
                                        <div>
                                            <input type="checkbox" kendoCheckBox #checked
                                                (change)="singleCheckboxChange($event,pharmacyName?.col_name,pharmacyName?.updated_value,pharmacyName?.category)"
                                                [checked]="markChecked" *ngIf="!(pharmacyName?.values_match)" />
                                        </div>
                                        <ion-text class="bodyS-txt"
                                            [ngClass]="!pharmacyName?.values_match ? 'ml-10' : 'ml-31'"
                                            color="primary100">{{pharmacyName?.attribute | titlecase}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt" color="gray80"
                                            [ngStyle]="{'font-weight': !pharmacyName?.values_match ? 'bolder' : ''}">{{pharmacyName?.updated_value}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt"
                                            color="gray80">{{pharmacyName?.existing_value}}</ion-text>
                                    </div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let initialKey of referralDetails">
                            <div class="grid-col-3 "
                                [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}"
                                [ngClass]="{'borderBottomGray05': !isUpdated ?  true : !(initialKey.values_match) ? true: false}">
                                <ng-container *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false ">

                                    <div class="d-flex align-items-center">
                                        <div>
                                            <input type="checkbox" kendoCheckBox #checked
                                                (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                                [checked]="markChecked" *ngIf="!(initialKey.values_match)" />
                                        </div>
                                        <ion-text class="bodyS-txt"
                                            [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                            color="primary100">{{initialKey.attribute | titlecase}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt" color="gray80"
                                            [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value}}</ion-text>
                                    </div>
                                    <div>
                                        <ion-text class="bodyS-txt"
                                            color="gray80">{{initialKey.existing_value}}</ion-text>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>

                </div>
                <div>
                    <div
                        *ngIf="!isUpdated ?  (insurancePrimary.length == 0) ? false : true: (calulatePrimaryInsurance > 0) ? true : false">
                        <div class="p-10 bg-lightBlue2">
                            <ion-text class="h5" color="gray80">Primary Insurance</ion-text>
                        </div>
                        <ng-container *ngFor="let initialKey of insurancePrimary">
                            <div class="grid-col-3 borderBottomGray05"
                                [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}"
                                *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false">

                                <div class="d-flex align-items-center">
                                    <div>
                                        <input type="checkbox" kendoCheckBox #checked
                                            (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                            [checked]="markChecked" *ngIf="!(initialKey.values_match)" />
                                    </div>
                                    <ion-text class="bodyS-txt" [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                        color="primary100">{{initialKey.attribute}}</ion-text>
                                </div>
                                <div> <ion-text class="bodyS-txt" color="gray80"
                                        [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value|
                                        slash }}</ion-text>
                                </div>
                                <div>
                                    <ion-text class="bodyS-txt" color="gray80">{{initialKey.existing_value| slash
                                        }}</ion-text>

                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div
                        *ngIf="!isUpdated ?  (insuranceSecondary.length == 0) ? false : true: (calulateSecondaryInsurance > 0) ? true : false">
                        <div class="p-10 bg-lightBlue2">
                            <ion-text class="h5" color="gray80">Secondary Insurance</ion-text>
                        </div>
                        <div class="grid-col-3 borderBottomGray05" *ngFor="let initialKey of insuranceSecondary"
                            [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}">
                            <ng-container *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false">

                                <div class="d-flex align-items-center">
                                    <div>
                                        <input type="checkbox" kendoCheckBox #checked
                                            (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                            [checked]="markChecked" *ngIf="!(initialKey.values_match)" />
                                    </div>
                                    <ion-text class="bodyS-txt" [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                        color="primary100">{{initialKey.attribute}}</ion-text>
                                </div>
                                <div>
                                    <ion-text class="bodyS-txt" color="gray80"
                                        [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value
                                        | slash }}</ion-text>

                                </div>
                                <div>
                                    <ion-text class="bodyS-txt" color="gray80">{{initialKey.existing_value | slash
                                        }}</ion-text>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div
                        *ngIf="!isUpdated ?  (insuranceTertiary.length == 0) ? false : true: (calulateTertiaryInsurance > 0) ? true : false">
                        <div class="p-10 bg-lightBlue2">
                            <ion-text class="h5" color="gray80">Tertiary Insurance</ion-text>
                        </div>
                        <div class="grid-col-3 borderBottomGray05" *ngFor="let initialKey of insuranceTertiary"
                            [ngStyle]="{'padding': !isUpdated ?  '10px ': !(initialKey.values_match) ? '10px': ''}">
                            <ng-container *ngIf="!isUpdated ?  true: !(initialKey.values_match) ? true : false">

                                <div class="d-flex align-items-center">
                                    <div>
                                        <input type="checkbox" kendoCheckBox #checked
                                            (change)="singleCheckboxChange($event,initialKey.col_name,initialKey.updated_value,initialKey.category)"
                                            [checked]="markChecked" *ngIf="!(initialKey.values_match)" />
                                    </div>
                                    <ion-text class="bodyS-txt" [ngClass]="!initialKey.values_match ? 'ml-10' : 'ml-31'"
                                        color="primary100">{{initialKey.attribute}}</ion-text>
                                </div>
                                <div>
                                    <ion-text class="bodyS-txt" color="gray80"
                                        [ngStyle]="{'font-weight': !initialKey.values_match ? 'bolder' : ''}">{{initialKey.updated_value
                                        | slash }}</ion-text>

                                </div>
                                <div>
                                    <ion-text class="bodyS-txt" color="gray80">{{initialKey.existing_value | slash
                                        }}</ion-text>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>

            <!-- <div class="border-darkBlue  mb-15" *ngFor="let form of listofFilledForms | keyvalue: originalOrder">
                <div class="py-10 px-10 bg-lightBlue2">
                    <ion-text class="h5" color="gray80"> Patient Forms
                    </ion-text>
                </div>
                <div class="grid-cols-2 flex-mob-col">
                    <div class="grid-col-4 p-10 borderBottomGray05" *ngFor="let item of formArray let i = index">
                        <div>
                            <input type="checkbox" kendoCheckBox disabled  />
                        </div>
                        <div>
                            <ion-text class="bodyS-txt"  (click)="singleFormOpen(item.id)" color="primary100">{{item.title}}</ion-text>

                        </div>
                    </div>
                </div> -->


        </div>
        <div class="pre-registration-patient-footer p-20 borderTopGray20 text-center ion-header-bg-color">
            <ion-button fill="clear" color="highlight" class="filled-clear-btn capitalize" (click)="closeClicked()">
                <ion-text>Close</ion-text>
            </ion-button>
            <ion-button [disabled]="!show" (click)="submitPreRegisters($event,'Reject')" fill="outline"
                color="highlight" class="filled-outline-btn capitalize mx-20" routerLink="/clinic-dashboard">
                <ion-text>Reject</ion-text>
            </ion-button>
            <ion-button *ngIf="!isAccepted" fill="solid" color="ion-color-white" class="filled-icon-btn capitalize"
                [disabled]="show" (click)="submitPreRegisters($event,'Accept')">
                <ion-text>Accept</ion-text>
            </ion-button>

            <ion-button *ngIf="isAccepted" fill="solid" color="ion-color-white" class="filled-icon-btn capitalize">
                <ion-text>Accept</ion-text>
            </ion-button>
        </div>
    </div>
    <div class="kendopopup editInfo_popup">
        <kendo-dialog *ngIf="isPatientFormOpened">
            <div class="patient_form_main">

                <!-- <kendo-dialog-titlebar style="border-bottom: 1px solid #CCD4D8">
                    <div class="d-flex w-100 align-items-center justify-content-sb ">
                      <div class="text-center w-100"> 
                        <ion-text class="h3-Montserrat tabletFont" color="gray100">Patient Health History</ion-text>
                      </div>
                        <ion-icon src="../../../../assets/svg/x.svg" color="gray80" class="h3 mr-10 cursor" (click)="hidePatientForm()"></ion-icon>
                    </div>
                  </kendo-dialog-titlebar> -->
            </div>
            <clinic-dashboard-form-preview (hideformpopup)="hideformpopup($event)"></clinic-dashboard-form-preview>
        </kendo-dialog>
    </div>
</div>
<!-- </div> -->
<app-idle></app-idle>