import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Subscription, concatMap, debounceTime, distinctUntilChanged, fromEvent, map, of, switchMap, tap } from 'rxjs';
import { regStatus } from 'src/app/core/Enums/regStatus.enum';
import { RequestPatient, patientModel } from 'src/app/core/models/patientModel';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardStoreService } from 'src/app/core/services/dashboard-store.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';

@Component({
  selector: 'app-clinic-dashboard-patient-list',
  templateUrl: './clinic-dashboard-patient-list.component.html',
  styleUrls: ['./clinic-dashboard-patient-list.component.scss'],
})
export class ClinicDashboardPatientListComponent implements OnInit {
  public loadingPanelVisible: boolean = true;
  public loadingPanelVisible1: boolean = false
  @ViewChild('searchPatient', { read: ElementRef }) searchPatient!: ElementRef;
  isNoResult: boolean = false;
  subscriptionSearch!: Subscription;
  patientAll: patientModel[] = [];
  patientConst: patientModel[] = [];
  searchTerm: string = '';
  patientRequest: RequestPatient =
    {
      tenantid: "",
      locationid: "",
      provider: [],
      appointmentdate: "2023-08-25",
      appointmenttypeids:[]
    };
  allChecked: boolean = true;
  preRegisteredChecked: boolean = false;
  notRegisteredChecked: boolean = false;
  array: any[] = [];
  getfilterpatient: any;
  getPatientList: any;
  getfilter: any;
  patientList: any;
  filteredPatientList: any;
  private subscription!: Subscription;
  currentDate: string;
  getPatientall: any;
  inprogresschecked: boolean = false;
  approvedchecked: boolean = false;
  completedchecked: boolean = false;
  patientName: string = '';
  sessionId: string = ''; 
  userRole: any;

  constructor(private dashboardService: DashboardStoreService,
    private datePipe: DatePipe,
    private router: Router,
    private commonService: CommonService,
    private patientService: PatientService,
    private appointService: AppointmentService,
    public popoverCtrl: PopoverController,
    private auditService: AuditService,
    private loginService: LoginService,) {
  }

  ngOnInit() { 
    this.patientName  = this.commonService.getSessionStorage("fn")
    this.sessionId  = this.commonService.getSessionStorage("sid")
    this.userRole = this.commonService.getSessionStorage('roles');
    let date: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.patientRequest.appointmentdate = date.toString();
    this.dashboardService.locationObservable$.pipe(
      tap(
        (item: any) => {
          if (item.length != 0) {
            this.patientRequest.locationid = item[0]?.locationid;
            this.patientRequest.tenantid = item[0]?.tenantid;
            this.getApptTypeByTenantId(item[0]?.tenantid,item[0]?.locationid)
           
          }
        }
      ),
      concatMap(
        (list1: any) => this.dashboardService.providerObservable$),
      tap(
        (item2: any) => {
          this.patientRequest.provider = item2.map((prov: any) => prov.providerid);
          if (item2.length != 0) {
            this.array = [];
          }
        }
      ),
      switchMap(
        (list2: any) => {
          if (this.patientRequest.provider.length !== 0)
            return this.dashboardService.getAllPatientByProvider(this.patientRequest)
          else return of('')
        }
      ),
    ).subscribe({
      next: this.getAllPatientByHandler.bind(this)
    });
    this.subscription = this.appointService.loaderClick$.subscribe(() => {
      this.loadingPanelVisible1 = true;
    });

    this.subscription = this.appointService.loaderClick1$.subscribe(() => {
      this.loadingPanelVisible = true;
    });
  }

  getAllPatientByHandler(object: any) {
    if (typeof object !== 'string' && object.length > 0) {
      this.patientList = object
      object.forEach((item: any) =>
        item.appointmentdate = item.appointmentdate.replace(' ', 'T')
      );

      if (object) {
        //  const filteredObjects = object.filter((item: any) => {
        // return item.usedstatus !== 7;
        //});

        this.patientAll = object;
        this.patientConst = object;
        this.filterPatientByStatus();
      }
      if (this.patientAll != null && this.patientAll.length > 0) {
        this.loadingPanelVisible = false;
        this.loadingPanelVisible1 = false;
        this.isNoResult = false;
      }
      else {
        this.isNoResult = true;
      }
    } else {
      this.patientAll = []
      this.loadingPanelVisible = false;
      this.loadingPanelVisible1 = false;
      this.isNoResult = true;
    }


  }

  filterPatientByStatus() {
    this.patientAll.filter((m: any) => {
      this.array.push(m.appointmentdetailsid)
    })
    const obj = {
      "sessionIds": this.array
    }
    this.patientService.filterPatientByStatus(obj).subscribe((res) => {
      if (res) {
        this.loadingPanelVisible1 = false;
        this.getPatientList = res;
        this.isPreRegisteredOrNot(this.getPatientList)
        this.filterPatients();
        this.loadingPanelVisible = false;
      }
    })
  }

  preRegisterPatientList: Array<string> = [];
  isPreRegisteredOrNot(patients: any) {
    if (patients) {
      this.patientAll.forEach((patient: any) => {
        let status = patients.filter((status: any) => status.sessionid === patient.appointmentdetailsid)[0];
        if (status.status === 'Pre-registered')
          this.preRegisterPatientList.push(status.sessionid)
      })

      //   this.patientAll.forEach((patient: any) => {
      //     this.getPatientList.filter((statusItem: any) => {
      //         if (statusItem.sessionid === patient.appointmentdetailsid) {
      //             Object.keys(statusItem).map(key => {
      //                 patient[key] = statusItem[key];
      //                 this.getPatientall=this.patientAll
      //             });
      //         }
      //     });
      // });
    }
  }
  ngAfterViewInit() {
    // Keyup event on searchbar.
    this.subscriptionSearch = fromEvent(this.searchPatient.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe({
      next: this.updateSearchByHandler.bind(this)
    });
  }

  // filtering the data on the basis of keyup event.
  async updateSearchByHandler(search: any) {
    this.auditService.writeOparationalLog('search patient#' + search);
    this.patientAll = await this.patientConst.filter((item: any) =>
      item.patientfirstname?.toLowerCase().includes(search.toLowerCase()) || item.patientlastname?.toLowerCase().includes(search.toLowerCase())
    );
    if (this.allChecked) {
      this.patientAll = this.patientAll;
    } else if (this.inprogresschecked) {
      this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'In-progress');
    } else if (this.completedchecked) {
      this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'Completed');
    } else if (this.approvedchecked) {
      this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'Approved');
    }

    // else if (this.preRegisteredChecked)
    //   this.patientAll = this.patientAll.filter((item: any) => this.preRegisterPatientList.includes(item.appointmentdetailsid));
    // else if (this.notRegisteredChecked)
    //   this.patientAll = this.patientAll.filter((item: any) => !this.preRegisterPatientList.includes(item.appointmentdetailsid));

    this.searchTerm = search;
    if (this.patientAll.length > 0)
      this.isNoResult = false;
    else
      this.isNoResult = true;
  }

  log() {
          this.auditService.writeOparationalLog('filter patient#All');
  }

  clear() {
    this.searchTerm = '';
    this.filterPatients();
    //console.log("cis -=> ",this.patientConst,this.preRegisteredChecked,this.notRegisteredChecked,this.patientConst)
    if (this.allChecked) {
      this.patientAll = this.patientConst;
    }
    else if (this.inprogresschecked) {
      this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'In-progress');
    } else if (this.completedchecked) {
      this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'Completed');
    } else if (this.approvedchecked) {
      this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'Approved');
    }

    if (this.patientAll.length > 0)
      this.isNoResult = false;
  }

  showNoResult() {
    this.isNoResult = true;
  }

  patientDetails(patient: any) {    
    let patientName = patient.patientfirstname + " " + patient.patientlastname
    const status = this.getPatientList?.find((status: any) => status.sessionid === patient.appointmentdetailsid);
    this.auditService.writeOparationalLog('Patient Selected#' + this.patientName + "#" + patientName+ "#"  + patient.patientid);
    if (status && status.state != 'Completed' && status.state != 'Approved') {
      this.commonService.setLocalStorage('user', JSON.stringify(patient));
      localStorage.setItem('isFrom', 'clinicPatient');
      this.commonService.setLocalStorage('type', '3');
       this.commonService.setSessionStorage("uifo", JSON.stringify(patient));
      this.commonService.setSessionStorage('pid', JSON.stringify(patient.patientid));
      this.commonService.setSessionStorage('tid', JSON.stringify(patient.tenantid));
      this.commonService.setSessionStorage('sid', JSON.stringify(patient.appointmentdetailsid));
      this.commonService.setSessionStorage('ptall', JSON.stringify(this.patientAll)); 
    //   let payload =  {
    //     "patientid": patient.patientid,
    //     "appointmentdetailid": patient.appointmentdetailsid,
    //     "tenantid": patient.tenantid
    // }
    //   this.patientService.savePortalFormById(payload).subscribe({
    //     next: (response: any) => {
          
    //     }
    //   })
      this.router.navigate(['/verify/', patient.appointmentdetailsid]);
    }
    //  else if (status && status.status === regStatus.Register && status.ispreregistered == false && status.isupdated == true) {
    //   this.commonService.setSessionStorage('patientid', JSON.stringify(patient.patientid));
    //   this.commonService.setSessionStorage('sessionid', JSON.stringify(patient.appointmentdetailsid));
    //   this.router.navigate(['/pre-register-patient/' + patient.patientid + '/' + patient.appointmentdetailsid]);
    // } else if (status && status.status === regStatus.Register && status.ispreregistered == true) {
    //   this.isAccepted = true;
    // } else if (status && status.status === regStatus.Register && status.ispreregistered == false && status.isupdated == false) {
    //   this.notUpdated = true;
    // }
    this.getPatientRegistrationDetails(patient);
    this.clear(); 
  }

  getPatientRegistrationDetails(patient: any) {
    const status = this.getPatientList?.find((status: any) => status.sessionid === patient.appointmentdetailsid);
    this.patientService.getPatientRegistrationDetails(patient.appointmentdetailsid).subscribe((res) => {
      if (res) {
        let patientStatus: any = res
        if (status && status.state === 'Completed' && patientStatus.isaccepted == false && patientStatus.isupdated == true && this.userRole?.includes("Review Patient Information")) {
       
          this.commonService.setSessionStorage('patientid', JSON.stringify(patient.patientid));
          this.commonService.setSessionStorage('sessionid', JSON.stringify(patient.appointmentdetailsid));
          this.router.navigate(['/pre-register-patient/' + patient.patientid + '/' + patient.appointmentdetailsid]);
        } else if (status && status.state === 'Approved' && patientStatus.isaccepted == true) {
          this.isAccepted = true;
        } else if (status && status.state === 'Completed' && patientStatus.isaccepted == false && patientStatus.isupdated == false) {
          this.notUpdated = true;
        } else if (status && status.state === 'Completed' && patientStatus.isaccepted == false && patientStatus.isupdated == true && !this.userRole?.includes("Review Patient Information")) {
          this.loginService.reviewPatientPermission();
        }

      }
    })
  }

  toggleCheckbox(value: string) {
    if (value === 'All' && this.allChecked) {
    } else if (value === 'Pre-registered' && this.preRegisteredChecked) {
    } else if (value === 'Not-registered' && this.notRegisteredChecked) {
    } else if (value === 'In-progress' && this.inprogresschecked) {
    } else if (value === 'Approved' && this.approvedchecked) {
    } else if (value === 'Completed' && this.completedchecked) {
    }
    else {
      this.allChecked = value === 'All' ? !this.allChecked : false;
      this.preRegisteredChecked = value === 'Pre-registered' ? !this.preRegisteredChecked : false;
      this.notRegisteredChecked = value === 'Not-registered' ? !this.notRegisteredChecked : false;
      this.inprogresschecked = value === 'In-progress' ? !this.inprogresschecked : false;
      this.approvedchecked = value === 'Approved' ? !this.approvedchecked : false;
      this.completedchecked = value === 'Completed' ? !this.completedchecked : false;
    }
    this.filterPatients();
  }
  filterPatients() {
    if (this.allChecked) {
      this.patientAll = this.patientList; // Show all patients
    } else {
      this.patientAll = this.patientList;
      if (this.searchTerm) {
        this.patientAll = this.patientAll.filter((item: any) =>
          item.patientfirstname?.toLowerCase().includes(this.searchTerm.toLowerCase()) || item.patientlastname?.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }

      if (this.preRegisteredChecked) {
        this.patientAll = this.patientAll.filter((item: any) => this.preRegisterPatientList.includes(item.appointmentdetailsid));
      } else if (this.notRegisteredChecked) {
        this.patientAll = this.patientAll.filter((item: any) => !this.preRegisterPatientList.includes(item.appointmentdetailsid));
      } else if (this.inprogresschecked) {
        this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'In-progress');
        this.auditService.writeOparationalLog('filter patient#In-progress');
      } else if (this.completedchecked) {
        this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'Completed');
        this.auditService.writeOparationalLog('filter patient#Completed');
      } else if (this.approvedchecked) {
        this.patientAll = this.patientAll.filter((patient: any) => patient.state === 'Approved');
        this.auditService.writeOparationalLog('filter patient#Approved');
      }
    }
    if (this.patientAll?.length === 0 && (!this.loadingPanelVisible && !this.loadingPanelVisible1)) {
      this.isNoResult = true;
    } else {
      this.isNoResult = false;
    }
    this.patientAll = this.patientAll;
    if (this.searchTerm !== '')
      this.updateSearchByHandler(this.searchTerm)

  }


  // filterPatients() {
  //   if (this.allChecked) {
  //     this.patientAll = this.patientList // Show all patients
  //     this.auditService.writeOparationalLog('filter patient#All');
  //   } else if (this.preRegisteredChecked) {
  //     this.patientAll = this.patientList.filter((patient: any) => {
  //       //const status = this.getPatientList.find((status: any) => status.sessionid === patient.appointmentdetailsid);
  //       return patient.status === 'Pre-registered';
  //     });
  //     this.auditService.writeOparationalLog('filter patient#Pre-registered');
  //   } else if (this.notRegisteredChecked) {
  //     this.patientAll = this.patientList.filter((patient: any) => {
  //       //const status = this.getPatientList.find((status: any) => status.sessionid === patient.appointmentdetailsid);
  //       return patient.status === 'Not-registered';
  //     });
  //     this.auditService.writeOparationalLog('filter patient#Not-registered');
  //   }
  //   else if (this.inprogresschecked) {
  //     this.patientAll = this.patientList.filter((patient: any) => {
  //       //const status = this.getPatientList.find((status: any) => status.sessionid === patient.appointmentdetailsid);
  //       console.log("searc h",this.searchTerm) 
  //       return patient.state === 'In-progress';
  //     });   

  //     this.auditService.writeOparationalLog('filter patient#In-progress');
  //   }
  //   else if (this.completedchecked) {
  //     this.patientAll = this.patientList.filter((patient: any) => {
  //       //const status = this.getPatientList.find((status: any) => status.sessionid === patient.appointmentdetailsid);
  //       return patient.state === 'Completed';
  //     });
  //     this.auditService.writeOparationalLog('filter patient#Completed');
  //   }
  //   else if (this.approvedchecked) {
  //     this.patientAll = this.patientList.filter((patient: any) => {
  //       //const status = this.getPatientList.find((status: any) => status.sessionid === patient.appointmentdetailsid);
  //       return patient.state === 'Approved';
  //     });
  //     this.auditService.writeOparationalLog('filter patient#Approved');
  //   }
  //   if (this.patientAll?.length === 0 && (!this.loadingPanelVisible && !this.loadingPanelVisible1)) {
  //     this.isNoResult = true;
  //   } else {
  //     this.isNoResult = false;
  //   }

  //   this.patientAll = this.patientAll;
  //   if (this.searchTerm !== '')
  //     this.updateSearchByHandler(this.searchTerm)
  // }
  openFormData(patientid: any, sessionid: any) {
    this.commonService.setSessionStorage('patientid', JSON.stringify(patientid));
    this.commonService.setSessionStorage('sessionid', JSON.stringify(sessionid));
  }
  isAccepted: boolean = false;
  notUpdated: boolean = false;

  closeAccepted() {
    this.auditService.writeOparationalLog('Not Update Popup#0');
    this.isAccepted = false;
    this.notUpdated = false;

  }

  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
    this.resetSearch();
  }


  resetSearch(): void {
    if (this.searchPatient) {
      const searchInput = this.searchPatient.nativeElement.querySelector('input');
      if (searchInput) {
        searchInput.value = ''; // Clear the search input value
      }
    }
  }

  //date
  onDateSelected(selectedDate: any) {
    const year = selectedDate.getFullYear();
    const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + selectedDate.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    this.currentDate = formattedDate
    if (this.currentDate) {
      this.patientRequest.appointmentdate = this.currentDate;
      this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
        this.getAllPatientByHandler(res);
      })
    }
    if (selectedDate) {
      this.popoverCtrl.dismiss()
    }
    this.currentDate = formattedDate
  }

  // decreaseDate() {
  //   const currentDateObj = new Date(this.currentDate);
  //   currentDateObj.setDate(currentDateObj.getDate() - 1);
  //   this.currentDate = this.datePipe.transform(currentDateObj, 'yyyy-MM-dd');
  //   console.log("decreaseDate", this.currentDate);
  //   if (this.currentDate) {
  //     this.patientRequest.appointmentdate = this.currentDate;
  //     this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
  //       this.getAllPatientByHandler(res);
  //     })
  //   }
  // }

  decreaseDate() {
    const currentDateObj = new Date(this.currentDate);
    currentDateObj.setDate(currentDateObj.getDate() - 1);
  
    // Convert the date to a string with the desired time zone
   this.currentDate = this.datePipe.transform(currentDateObj, 'yyyy-MM-dd', 'UTC');

  
    if (this.currentDate) {
      this.patientRequest.appointmentdate = this.currentDate;
      this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
        this.getAllPatientByHandler(res);
      });
    }
  }

  // increaseDate() {
  //   const currentDateObj = new Date(this.currentDate);
  //   currentDateObj.setDate(currentDateObj.getDate() + 1);
  //   this.currentDate = this.datePipe.transform(currentDateObj, 'yyyy-MM-dd');
  //   console.log("increaseDate", this.currentDate);
  //   if (this.currentDate) {
  //     this.patientRequest.appointmentdate = this.currentDate;
  //     this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
  //       this.getAllPatientByHandler(res);
  //     })
  //   }
  // }

  increaseDate() {
    const currentDateObj = new Date(this.currentDate);
    currentDateObj.setDate(currentDateObj.getDate() + 1);
  
    // Convert the date to a string with the desired time zone
    this.currentDate = this.datePipe.transform(currentDateObj, 'yyyy-MM-dd', 'UTC');
  
  
    if (this.currentDate) {
      this.patientRequest.appointmentdate = this.currentDate;
      this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
        this.getAllPatientByHandler(res);
      });
    }
  }

  // displayToday() {
  //   this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  //   if (this.currentDate) {
  //     this.patientRequest.appointmentdate = this.currentDate;
  //     this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
  //       this.getAllPatientByHandler(res);
  //     })
  //   }
  // }

  displayToday() {
    
    const currentDate = new Date();
  
    this.currentDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd', 'UTC');
  
    if (this.currentDate) {
      this.patientRequest.appointmentdate = this.currentDate;
      this.dashboardService.getAllPatientByProvider(this.patientRequest).subscribe((res) => {
        this.getAllPatientByHandler(res);
      });
    }
  }

  getApptTypeByTenantId(tenantid: any,locationid:any) {
    this.patientService.getAllAppointmentTypesSelectedByTenantId(tenantid,locationid).subscribe((res: any) => {
      this.patientRequest.appointmenttypeids = res?.map((appt: any) => appt.appointmenttypeid);
    });
  }
}