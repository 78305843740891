     
  <div class="signout_popup ">
    <!-- routerLink="/practice-list" -->
     <div class="px-10 py-10 cursor borderBottomGray20" *ngIf="tenantLength.length>1" (click)="removetenant()">
      <ion-text class="bodyM-txt" color="gray80" >Change Pratice</ion-text>
    </div>
    
    <div class="px-10 py-10 cursor borderBottomGray20" *ngIf="auditRole && reportShow !== 'Audit Report'" (click)="navigateToAuditReport()">
      <ion-text class="bodyM-txt" color="gray80" >Audit Report</ion-text>
    </div>
    
    <div class="px-10 py-10 cursor borderBottomGray20" *ngIf="userRole && reportShow !== 'User Report'" (click)="navigateToUsageReport()">
      <ion-text class="bodyM-txt" color="gray80" >Usage Report</ion-text>
    </div>
    
    <div (click)="logout()" class="cursor px-10 py-10">
      <ion-text class="bodyM-txt " color="gray80">Logout</ion-text>
    </div>
  </div> 
