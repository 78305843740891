import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {
token:any;

  
constructor(private http:HttpClient,private commonService: CommonService) { }
 
private getToken(): string {
  this.token =  this.commonService.getSessionStorage('PI');
  const parsedToken =(this.token);

  return parsedToken.token;
}
  
  getPracticeDetailsByTenantId(tenantId: any): Observable<any> {
    const usetoken = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + usetoken
      })
    }
    return this.http.get<any>(environment.auth_url + "/tenant/getTenantDetailsByID/" + tenantId, httpOptions);

  }
}
