import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-dashboard-search-noresult',
  templateUrl: './clinic-dashboard-search-noresult.component.html',
  styleUrls: ['./clinic-dashboard-search-noresult.component.scss'],
})
export class ClinicDashboardSearchNoresultComponent implements OnInit {

  @Input("search") search: string = '';
  @Input("searchTerm") searchTerm: string = '';

  constructor() { }

  ngOnInit() {}

}
