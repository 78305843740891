import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { Keyboard } from '@capacitor/keyboard';
import { LocationStrategy } from '@angular/common';
import { Subject } from 'rxjs';
import { AuditService } from '../../pages/AuditService/audit.service';
declare function global(): void;
@Component({
  selector: 'app-confirm-patient',
  templateUrl: './confirm-patient.component.html',
  styleUrls: ['./confirm-patient.component.scss'],
})

export class ConfirmPatientComponent implements OnInit, AfterViewInit {
  isConfirm: boolean = true;
  confirmationform!: FormGroup;
  selectedDate: string = '';
  // token: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  pid: any;
  patientDate: any;
  formattedDate: any;
  inputdate: any;
  getDob: any;
  formatDob: any;
  public errorMessage!: string;
  dateFormat: any;
  isDobVerified: boolean = false;
  patients: any;

  replaceNextInput: boolean = true;
  sessionId: any;
  patientId: any;
  @ViewChild("dobInput") dob !: any;
  isClicked: boolean = false;
  isPatient: boolean
  patientInfo: any;
  tenantId: any;
  tenantName: any;
  appointmentData!: any;
  constructor(private fb: FormBuilder,
    private login: LoginService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private patientService: PatientService,
    private commonService: CommonService,
    private renderer: Renderer2,
    private locationStrategy: LocationStrategy,
    private auditService: AuditService,
  ) {
    // global();
    //this.initNoBack();
    this.confirmationform = this.fb.group({
      dob: ['', this.validateDate]
    });
  }
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: PopStateEvent) {
  //   console.log('Back button clicked after page reload');
  //   window.history.pushState(null, document.title, window.location.href);
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // handleBeforeUnload(event: Event) {
  //   event.preventDefault();
  // }
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   console.log('efefef');
  //   this.location.forward();

  //   // const patientid = this.commonService.getSessionStorage('pid');
  //   // if (patientid) {
  //   //   console.log(event);
  //   //   this.location.forward();
  //   // }
  // }
  ngOnInit() {
    // let sessionUser = this.commonService.getSessionStorage('user');
    // localStorage.setItem('user', JSON.stringify(sessionUser));
    // localStorage.setItem('type', '2');
    // window.history.pushState(null, null, null)
    // history.pushState(null, '');

    // fromEvent(window, 'popstate').pipe(
    //   takeUntil(this.unsubscriber)
    // ).subscribe((_) => {
    //   history.pushState(null, '');
    //   // this.showErrorModal(`You can't make changes or go back at this time.`);
    // });

    //this.disableBackButton();
    this.pid = this.commonService.getSessionStorage('pid');
    this.sessionId = this.commonService.getSessionStorage('sid');
    this.patientId = this.commonService.getSessionStorage('pid');
    this.isPatient = this.commonService.getSessionStorage('isPt');
    this.patientInfo= this.commonService.getSessionStorage('uifo');
    this.tenantId=this.commonService.getSessionStorage('tid');
    this.tenantName=this.commonService.getSessionStorage('TN')
    if (this.commonService.getSessionStorage('isVerified') === 'true') {
      this.isConfirm = false;
    }
    this.actRoute.params.subscribe({
      next: (response: any) => {
        this.patients = response.id;
      }
    });
    this.getAppointmentDetails()
  }

  // @HostListener('window:beforeunload', ['$event'])
  // handleBeforeUnload(event: Event) {
  //   event.returnValue = false;
  // }
  disableBackButton() {
    history.pushState(null, '', window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, '', window.location.href);
    });

    // history.pushState(null, null, window.location.href);
    // this.platformLocation.onPopState(() => {
    //   console.log('pressed back!');
    //   history.pushState(null, null, window.location.href);
    // });
  }
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
  ngAfterViewInit() {
    this.getCursorPosition();
  }

  //@HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    // Get the character code
    const charCode = event.which || event.keyCode;

    // Allow numeric characters and slash ("/")
    if ((charCode >= 48 && charCode <= 57) || charCode === 47) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  //function for keypad
  onKeypadClick(digit: any) {
    // const currentInput = this.confirmationform.get('dob')?.value;
    // const cursorPosition = this.getCursorPosition();
    // // Handle backspace (delete) key press
    // if (digit === 'backspace' && cursorPosition > 0) {
    //   // Remove the character before the cursor position
    //   const newValue = currentInput.slice(0, cursorPosition - 1) + currentInput.slice(cursorPosition);
    //   this.confirmationform.get('dob')?.setValue(newValue);
    //   this.setFocusAndCursor(cursorPosition - 1);
    //   return;
    // }
    // // Handle regular digit input
    // if (currentInput.length < 10) {
    //   let newInput = currentInput.slice(0, cursorPosition) + digit + currentInput.slice(cursorPosition);

    //   // Check if adding a digit requires adding a slash
    //   if (newInput.length === 2 || newInput.length === 5) {
    //     newInput += '/';
    //   }
    //   this.confirmationform.get('dob')?.setValue(newInput);
    //   // Calculate the new cursor position based on the digit insertion
    //   this.setFocusAndCursor(cursorPosition + 1);
    // }
    const currentInput = this.confirmationform.get('dob')?.value;
    let cursorPosition = this.getCursorPosition();

    // Handle backspace (delete) key press
    if (digit === 'backspace' && cursorPosition > 0) {
      const newValue = currentInput.slice(0, cursorPosition - 1) + currentInput.slice(cursorPosition);
      this.confirmationform.get('dob')?.setValue(newValue);
      this.setFocusAndCursor(cursorPosition - 1);
      return;
    }

    // Handle regular digit input
    if (currentInput.length < 10) {
      let newInput = currentInput.slice(0, cursorPosition) + digit + currentInput.slice(cursorPosition);

      // Check if adding a digit requires adding a slash
      if (newInput.length === 2 && digit !== '/') {
        newInput += '/';
        cursorPosition += 1; // Move cursor after the added slash
      } else if (newInput.length === 5 && digit !== '/') {
        newInput += '/';
        cursorPosition += 1; // Move cursor after the added slash
      }

      this.confirmationform.get('dob')?.setValue(newInput);

      // Calculate the new cursor position based on the digit insertion
      cursorPosition += (digit === '/' ? 2 : 1);

      this.setFocusAndCursor(cursorPosition);
    }
  }



  //function for validate date format
  validateDate(control: FormControl) {
    const inputDate = control.value;
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY format
    if (datePattern.test(inputDate)) {
      const parts = inputDate.split('/');
      const month = parseInt(parts[0], 10);
      const day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);
      const currentDate = new Date();
      if (
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= 31 &&
        year >= 1900 &&
        year <= currentDate.getFullYear() &&
        new Date(year, month - 1, day) <= currentDate // Check for future date
      ) {
        return null; // Valid date
      } else {
        return { invalidDate: true };
      }
    } else {
      return { invalidFormat: true };
    }
  }

  // function for delete on keypad
  onDelete() {
    const currentInput = this.confirmationform.get('dob')?.value;
    const cursorPosition = this.getCursorPosition();

    if (cursorPosition > 0) {
      let newValue = currentInput;
      let newCursorPosition = cursorPosition;

      // Determine if the cursor is at a slash
      const isCursorAtSlash = currentInput[cursorPosition - 1] === '/';

      if (currentInput.length === 1 || (isCursorAtSlash && currentInput.slice(cursorPosition).trim() === '/')) {
        // If there's only one character left or the cursor is at a slash and all characters after the slash are spaces, clear the entire input
        newValue = '';
        newCursorPosition = 0;
      } else if (cursorPosition === currentInput.length && currentInput.endsWith('/')) {
        // If the cursor is at the end of the input and the last character is a slash, just remove the slash
        newValue = currentInput.slice(0, cursorPosition - 1);
        newCursorPosition--; // Move the cursor one position back
      } else {
        // If the cursor is not at a slash or at the end of the input, remove the character before it
        newValue = currentInput.slice(0, cursorPosition - 1) + currentInput.slice(cursorPosition);
        newCursorPosition--; // Move the cursor one position back
      }

      // Update the form value and trigger change detection
      this.confirmationform.get('dob')?.setValue(newValue);
      this.cdr.detectChanges();

      // Set the cursor position to the updated position
      this.setFocusAndCursor(newCursorPosition);
    }
  }


  getCursorPosition(): any {
    // const inputElement = document.querySelector('kendo-textbox input') as HTMLInputElement;
    // return inputElement ? inputElement.selectionStart : 0;
    const inputElement = this.el.nativeElement.querySelector('kendo-textbox input') as HTMLInputElement;
    if (inputElement && inputElement.selectionStart !== null) {
      const cursorPosition = inputElement.selectionStart;
      return cursorPosition;
    }
    return 0;
  }

  private setFocusAndCursor(position: number): void {
    // const inputElement = document.querySelector('kendo-textbox input') as HTMLInputElement;
    // if (inputElement) {
    //   inputElement.focus();
    //   inputElement.setSelectionRange(position, position);
    // }

    // const inputElement = this.el.nativeElement.querySelector('kendo-textbox input') as HTMLInputElement;
    // if (inputElement) {
    //   // Ensure the position is within the input length
    //   position = Math.min(position, inputElement.value.length);
    //   // Set the cursor position
    //   inputElement.setSelectionRange(position, position);
    //   inputElement.focus();
    // }
    const inputElement = this.el.nativeElement.querySelector('kendo-textbox input') as HTMLInputElement;

    // const inputElement = this.el.nativeElement.querySelector('kendo-textbox input') as HTMLInputElement;

    if (inputElement) {
      requestAnimationFrame(() => {
        // Ensure the position is within the input length
        position = Math.min(position, inputElement.value.length);

        // Set the cursor position
        inputElement.setSelectionRange(position, position);
        inputElement.focus();
      });
    }
  }


  // verify() {
  //   this.isClicked=true
  //   this.inputdate = this.confirmationform.get('dob')?.value;
  //   this.dateFormat = this.datePipe.transform(this.inputdate, 'yyyy-MM-dd');
  //   this.login.getPatientDob(this.pid, this.dateFormat).subscribe((res: any) => {
  //     if (res == true) {
  //       let session = {
  //         sessionid: this.sessionId,
  //         sessionstartedby: this.patientId
  //       };
  //       this.patientService.saveSession(session).subscribe((res) => {
  //         if (res) {
  //           // Clear form fields and set verification status
  //           this.isDobVerified = true;
  //           this.router.navigate(['app/welcome']).then(() => {
  //             // Reset isClicked after navigation is completed
  //             this.isClicked = false;
  //             this.confirmationform.get('dob')?.setValue('');
  //             this.confirmationform.get('dob')?.markAsPristine(); // Mark the control as pristine
  //             this.confirmationform.get('dob')?.markAsUntouched();
  //           });
  //           this.errorMessage = '';
  //           sessionStorage.setItem('isVerified', JSON.stringify(this.isDobVerified));
  //           localStorage.setItem('isFrom', 'patientVerification');
  //           // Navigate to the welcome page
  //           // this.getPatientDetails();

  //         }
  //       });

  //     } else {
  //       this.isConfirm = true;
  //       this.isClicked = false;
  //       this.errorMessage = 'This does not match the date of birth we have on file for you.';
  //       // this.alertLoginFailed(this.errorMessage);
  //     }
  //   });

  // }

  
  getAppointmentDetails() {
    this.patientService.getAppointmentDetails(this.sessionId).subscribe((res: any) => {
      if (res) {
        // this.commonService.setSessionStorage("appointmentid",JSON.stringify(res.appointmenttypeid))
        this.appointmentData = res;
      }
    });
  }

  verify() {
    this.isClicked = true;
    this.inputdate = this.confirmationform.get('dob')?.value;
    this.dateFormat = this.datePipe.transform(this.inputdate, 'yyyy-MM-dd');

    this.login.getPatientDob(this.pid, this.dateFormat).subscribe((res: any) => {
      if (res == true) {
        let patientsession = this.isPatient ? "Self" : "Clinic"
        let session = {
          sessionid: this.sessionId,
          sessionstartedby: this.patientId,
          sourceworkflow: patientsession,
          tenantid:this.tenantId,
          tenantname:this.tenantName,
          locationid:this.patientInfo?.locationid,
          locationname:this.patientInfo?.locationname,
          appointmenttypeid:this.appointmentData?.appointmenttypeid,
          appointmenttypedescription:this.appointmentData?.appointmenttypedesc
        };
        // console.log("pp =>",session)
        this.patientService.saveSession(session).subscribe((res) => {
          if (res) {
            // Clear form fields and set verification status
            this.isDobVerified = true;
            this.router.navigate(['app/welcome']).then(() => {
              // Reset isClicked after navigation is completed

              this.isClicked = false;
              this.confirmationform.get('dob')?.setValue('');
              this.confirmationform.get('dob')?.markAsPristine(); // Mark the control as pristine
              this.confirmationform.get('dob')?.markAsUntouched();
              this.getPatientDetails('successful');
            });
            this.errorMessage = '';
            this.commonService.setSessionStorage('isVerified', JSON.stringify(this.isDobVerified));
            localStorage.setItem('isFrom', 'patientVerification');
            // Navigate to the welcome page
            // this.getPatientDetails();
          }
        }, (error) => {
          this.isClicked = false;
          this.errorMessage = 'An error occurred while saving session.';
          // Handle error
        });

      } else {
        this.getPatientDetails('failed');
        this.isClicked = false;
        this.isConfirm = true;
        this.errorMessage = 'This does not match the date of birth we have on file for you.';
        // this.alertLoginFailed(this.errorMessage);
      }
    }, (error) => {
      this.isClicked = false;
      this.errorMessage = 'An error occurred while fetching patient date of birth.';
      this.getPatientDetails('failed');
      // Handle error
    });
  }

  getPatientDetails(status) {
    this.patientService.patientDemographicDetails(this.patientId).subscribe((res: any) => {
      if (res) {
        this.auditService.writeOparationalLog('Verify DOB#' + res.patientcode + '#' + status);
      }
    })
  }

  hide() {
    this.renderer.setAttribute(this.dob.input.nativeElement, "readonly", "readonly")
    setTimeout(
      () => {
        this.renderer.removeAttribute(this.dob.input.nativeElement, "readonly")
      }
      , 100);
    // console.log("dob ==> ",this.dob.nativeElement,this.dob.nativeElement.value)
  }

  blur() {
    setTimeout(() => {
      Keyboard.hide()
    }, 10)
  }

  navigateToLogin() {
    this.confirmationform.controls['dob'].setValue('');
    this.errorMessage = '';
    sessionStorage.removeItem('PI');
    sessionStorage.removeItem('url');
    sessionStorage.removeItem('UID');
    sessionStorage.removeItem('sid');
    sessionStorage.removeItem('uifo');
    sessionStorage.removeItem('tid');
    sessionStorage.removeItem('pid');
    sessionStorage.removeItem('isVerified');
    sessionStorage.removeItem('xyz');
    //sessionStorage.clear()
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    this.router.navigate(['/clinic']);
    localStorage.removeItem('pcp');
    localStorage.removeItem('pname');
  }

  // initNoBack(): void {
  //   const _hash = "!";
  //   window.location.href += "#";
  //   setTimeout(() => {
  //     window.location.href += "!";
  //   }, 50);

  //   window.onhashchange = () => {
  //     if (window.location.hash !== _hash) {
  //       window.location.hash = _hash;
  //     }
  //   };

  //   window.onload = () => {
  //     document.body.onkeydown = (e) => {
  //       alert(e.target)
  //       const elm = (e.target as HTMLElement)?.nodeName?.toLowerCase();
  //       if (e.which === 8 && (elm !== 'input' && elm !== 'textarea')) {
  //         e.preventDefault();
  //       }
  //       e.stopPropagation();
  //     };
  //   };
  // }

}
