<!-- <script type="text/javascript"> 
  window.history.forward(); 
  function noBack() { 
    window.history.forward(); 
  } 
</script>  -->
<div class="clinic-dashboard-main main_container" style="background-color: #fff;">
  <app-clinic-dashboard-header></app-clinic-dashboard-header>
  <div class="d-flex">
    <app-clinic-dashboard-provider-list></app-clinic-dashboard-provider-list>
    <app-clinic-dashboard-patient-list class="w-100"></app-clinic-dashboard-patient-list>
  </div>
</div>

<app-idle></app-idle>
