import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: Date, ...args: unknown[]): unknown {
    let date = this.datePipe.transform(value, 'MM/dd/yyyy')
    return date;
  }

}
