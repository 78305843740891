import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { auditcls } from 'src/app/main/pages/AuditService/auditLog';
import { getLogData } from 'src/app/shared/getLogData';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';

@Injectable({
  providedIn: 'root'
})
export class RestrictGuard implements CanActivate {
  visitedPage: any;
  private auditObj = new auditcls();
  public ip: any = '';
  public fullname: any = '';
  public user: any = '';
  userid: any;
  public ClientBrowser: any = '';
  public patientid: any;
  public tenantid: any;
  public workflow: any = '';

  country: any;
  continent: any;
  city: any;
  countryCode: any;
  region: any;
  ClientIP: any;
  message: any;
  useraction: any;
  constructor(private router: Router, private auditService: AuditService,
    private commonService: CommonService, private location: Location, private authService: AuthService) { }
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   let lStorage = this.commonService.getSessionStorage('UID');
  //   let isPatient = this.commonService.getSessionStorage('isPt')
  //   if (isPatient) {
  //     this.router.navigate(['/app']);
  //     this.location.back();
  //     return false
  //   } else if (lStorage) {
  //     return false
  //   }
  //   return true;
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.visitedPage = state.url;
    this.getClientDetails();

    let isFromClinicPatient = (localStorage.getItem('isFrom') === 'clinicPatient') ? true : false;
    if (this.authService.isClinicPatientLoggedIn() && isFromClinicPatient) {
      return true;
    }
    else {
      console.log('isFromClinicPatient', isFromClinicPatient);
      this.authService.logout();
      this.router.navigateByUrl('/error?code=510');
      return false;
    }
  }

  getClientDetails() {
    let page = this.visitedPage.split('/');

    this.ip = '';
    this.user = this.commonService.getSessionStorage("usr");
    this.ClientBrowser = getLogData.getbroweser();
    this.fullname = this.commonService.getSessionStorage("fn");;
    this.userid = this.commonService.getSessionStorage("usid");
    this.tenantid = this.commonService.getSessionStorage("tnt");
    this.patientid = this.commonService.getSessionStorage("pid") ? this.commonService.getSessionStorage("pid") : this.commonService.getSessionStorage("patientid") ? this.commonService.getSessionStorage("patientid") : '';
    this.workflow = (this.commonService.getSessionStorage("isPt")) ? 'Mobile workflow - ' : 'In-Office workflow - ';

    // console.log("this.patientid=>", this.patientid);
    this.country = '';
    this.countryCode = '';
    this.continent = '';
    this.region = '';
    this.city = '';
    this.auditObj.application = 'QIntake';

    this.auditObj.loginuser = this.user;
    this.auditObj.clientip = this.ip ? this.ip : '';
    this.auditObj.clientbrowser = this.ClientBrowser ? this.ClientBrowser : '';
    this.auditObj.userfullname = this.fullname ? this.fullname : '';
    this.auditObj.userid = this.userid ? this.userid : '';
    this.auditObj.tenantid = this.tenantid ? this.tenantid : '';
    this.auditObj.patientid = this.patientid ? this.patientid : '';
    this.auditObj.country = this.country ? this.country : '';
    this.auditObj.countrycode = this.countryCode ? this.countryCode : '';
    this.auditObj.continent = this.continent ? this.continent : '';
    this.auditObj.region = this.region ? this.region : '';
    this.auditObj.city = this.city ? this.city : '';
    this.auditObj.application = 'QIntake';
    // console.log(this.visitedPage);

    switch (this.visitedPage) {

      case '/verify/' + page[2]: {
        this.auditObj.message = this.workflow + 'accessed verify DOB screen';
        this.auditObj.module = 'Verify patient DOB module';
        this.auditObj.screen = 'Verify DOB';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = page[2] ?? '0';
        this.auditService.callApI(this.auditObj);
        // console.log(" audit service VG ***************   ", this.auditObj);
        break;
      }
      default: {
        break;
      }
    }
  }
}
