import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { getLogData } from '../../shared/getLogData';
import { auditcls } from '../../main/pages/AuditService/auditLog';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';
import { AuthService } from '../services/auth.service';
import { clinicEnum } from '../Enums/clinic.enum';
import { LoginService } from '../tablet-service/login.service';
//import { CommonService } from '../services/common.service';


@Injectable({
  providedIn: 'root'
})
export class ClinicGuard implements CanActivate {
  visitedPage: any;
  clientBrowser: any;
  private auditObj = new auditcls();
  public ip: any = '';
  public fullname: any = '';
  public user: any = '';
  userid: any;
  public ClientBrowser: any = '';
  public patientid: any;
  public tenantid: any;
  public patientAll: any;
  public workflow: any = '';

  country: any;
  continent: any;
  city: any;
  countryCode: any;
  region: any;
  ClientIP: any;
  message: any;
  useraction: any;
  userRole: any;
  token: any;
  permissionListItem: any = [];
  permissionFlag: boolean = false;
  calendarPermissionFlag: boolean = false;
  localData: any = [];
  constructor(private router: Router,
    private commonService: CommonService, private auditService: AuditService, private authService: AuthService, private loginService: LoginService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let lStorage = this.commonService.getSessionStorage('UID');
    this.visitedPage = state.url;
    if (lStorage === null || lStorage === undefined || lStorage == '') {
      this.router.navigateByUrl('/clinic'); // Redirect to the '/clinic' route
      
      if (this.visitedPage == '/app/audit-report') {
        this.commonService.setSessionStorage("redirectTo", JSON.stringify('auditreport'));
      }
      if (this.visitedPage == '/app/usage-report') {
        this.commonService.setSessionStorage("redirectTo", JSON.stringify('usagereport'));
      }
      return false;
    }

    if (this.authService.isPracticeLoggedIn()) {
      this.getClientDetails();
      return true;
    } else if (this.authService.isPatientLoggedIn()) {
      this.authService.logout();
      this.router.navigateByUrl('/error?code=510');
      return false;
    } else if (this.authService.isClinicPatientLoggedIn()) {
      this.authService.logout();
      this.router.navigateByUrl('/error?code=510');
      return false;
    } else {
      this.authService.logout();
      this.router.navigateByUrl('/clinic');
      return false;
    }
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   let lStorage = this.commonService.getSessionStorage('UID');
  //   let lStorage1 = sessionStorage.getItem('isVerified');
  //   let pid = this.commonService.getSessionStorage("pid") ? this.commonService.getSessionStorage("pid") : this.commonService.getSessionStorage("patientid") ? this.commonService.getSessionStorage("patientid") : '';
  //   this.workflow = (this.commonService.getSessionStorage("isPt")) ? 'Mobile workflow - ' : 'In-Office workflow - ';
  //   if (this.commonService.getSessionStorage('PI')) {
  //     this.visitedPage = state.url;
  //     this.getClientDetails();
  //     // console.log("can Activate called ----");
  //   //  return true;
  //   }
  //   if (lStorage === null || lStorage === undefined || lStorage == '') {
  //     this.router.navigateByUrl('/clinic'); // Redirect to the '/clinic' route
  //     return false;
  //   } else if (lStorage1 === 'true' || pid) {
  //     let session = this.commonService.getSessionStorage('sid');
  //     this.router.navigate(['/verify/' + session]); // Redirect to the 'verify' route with 'sid'
  //     return false;
  //   } else {
  //     //this.disableBackButton();
  //     return true;
  //   }
  // }

  private disableBackButton() {
    history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function () {
      history.pushState(null, document.title, window.location.href);
    });
  }

  getClientDetails() {
    this.userRole = this.commonService.getSessionStorage('roles');
    let page = this.visitedPage.split('/');

    this.ip = '';
    this.user = this.commonService.getSessionStorage("usr");
    this.ClientBrowser = getLogData.getbroweser()
    this.fullname = this.commonService.getSessionStorage("fn");;
    this.userid = this.commonService.getSessionStorage("usid");
    this.tenantid = this.commonService.getSessionStorage("tnt");
    this.patientid = this.commonService.getSessionStorage("pid") ? this.commonService.getSessionStorage("pid") : this.commonService.getSessionStorage("patientid") ? this.commonService.getSessionStorage("patientid") : '';
    this.workflow = (this.commonService.getSessionStorage("isPt")) ? 'Mobile workflow - ' : 'In-Office workflow - ';
    // this.patientAll = this.commonService.getSessionStorage("ptall");
    // console.log("this.patientid=>", this.patientid);
    this.country = '';
    this.countryCode = '';
    this.continent = '';
    this.region = '';
    this.city = '';
    // console.log(JSON.stringify(this.tenantid));

    this.auditObj.loginuser = this.user;
    this.auditObj.clientip = this.ip ? this.ip : '';
    this.auditObj.clientbrowser = this.ClientBrowser ? this.ClientBrowser : '';
    this.auditObj.userfullname = this.fullname ? this.fullname : '';
    this.auditObj.userid = this.userid ? this.userid : '';
    this.auditObj.tenantid = this.tenantid ? this.tenantid : '';
    this.auditObj.patientid = this.patientid ? this.patientid : '';
    this.auditObj.country = this.country ? this.country : '';
    this.auditObj.countrycode = this.countryCode ? this.countryCode : '';
    this.auditObj.continent = this.continent ? this.continent : '';
    this.auditObj.region = this.region ? this.region : '';
    this.auditObj.city = this.city ? this.city : '';
    this.auditObj.application = 'QIntake';
    // console.log(this.visitedPage);


    switch (this.visitedPage) {

      case '/clinic': {
        this.auditObj.message = this.workflow + 'accessed clinic login screen';
        this.auditObj.module = 'Clinic lock-screen module';
        this.auditObj.screen = 'Clinic login';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.auditService.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        break;
      }

      case '/clinic-dashboard': {
        this.auditObj.message = this.workflow + 'accessed clinic dashboard';
        this.auditObj.module = 'Clinic dashboard module';
        this.auditObj.screen = 'Clinic dashboard';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.auditService.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        if (!this.userRole?.includes("Clinic Dashboard")) {
          this.loginService.permissionError();
        }
        break;
      }

      case '/app/audit-report': {
        this.auditObj.message = this.workflow + 'accessed audit report dashboard';
        this.auditObj.module = 'audit report module';
        this.auditObj.screen = 'Audit Report';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.auditService.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        if (!this.userRole?.includes("Audit Report")) {
          this.loginService.permissionError();
        }
        break;
      }

      case '/practice-list': {
        this.auditObj.message = this.workflow + 'accessed practice list';
        this.auditObj.module = 'Clinic practice list module';
        this.auditObj.screen = 'Practice list';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.auditService.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        break;
      }

      case '/app/audit-report': {
        this.auditObj.message = this.workflow + 'accessed audit report';
        this.auditObj.module = 'Audit report module';
        this.auditObj.screen = 'Audit report';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.auditService.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        break;
      }

      case '/pre-register-patient/' + page[2] + '/' + page[3]: {

        this.auditObj.message = this.commonService.getSessionStorage("fn") + ' viewed the Updated information for a Patient ' + this.patientid;
        this.auditObj.module = 'Clinic patient preview module';
        this.auditObj.screen = 'Information Review Screen';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = page[2] ?? '0';
        this.auditObj.patientid = page[2] ?? '0';
        this.auditService.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        break;
      }

      default: {
        break;
      }
    }
  }
}


