import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { PaymentService } from 'src/app/services/payment.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService, private paymentService: PaymentService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const jwt: any = this.commonService.getSessionStorage('PI')
    // const token:any=this.commonService.getSessionStorage('tk')
    let PI = jwt;
    if (PI) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${PI.token}`
        }
      })
    }
    // const jwt1: any ="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDgzMTY3NzEsImV4cCI6MTcxMDkwODc3MSwiaXNzIjoiY2xvdWQtZW5kcG9pbnRzQHFwYXRod2F5cy1kZXYuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL3FvcmUtZGV2Lm15cW9uZS5jb20iLCJzdWIiOiJjbG91ZC1lbmRwb2ludHNAcXBhdGh3YXlzLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImVtYWlsIjoiZ2F5YXRyaS5oYXJpc2hjaGFuZHJlQHRyaWFycWhlYWx0aC5jb20iLCJ1c2VyaWQiOiIxN2I2YTI3OS1kMzc2LTExZWQtYmVmMi00YjE3MjNjMTExMGIiLCJmaXJzdG5hbWUiOiJnYXlhdHJpIiwibGFzdG5hbWUiOiJoYXJpc2hjaGFuZHJlIn0.PqOySnrTqpEjn649UlsxMlHhDoJptJtFMxdW2CL6rktJw3P84NwjrsyLCUgLg5hi5WCyh6K7YAhfeqszO4vhSqh7rRtVhugUyV7il1VfC9NGTheH1XcKFVfoSM4V0IEEMVKam1Yi_nrkE9bMo_bkp8j16KtN9OXp2_yT08Yz6DbtbtKS4tUQW5PS4v3I3n-XasTPKIRjr30iCfE5Nq4TS1Y6bvuL57vZfOLOuj7rkXn5UTcihnV9w9DF7R3CSBm4A2o5lKnpbrW1plZXqsUHDbsHO5SKf4EkaylP5uTidkBSZui2qbbBxHa5TIhcXwuOe9G1IzcLzZS2mJQUYu2lGw"
    let jwt1 = this.commonService.getSessionStorage('tk')
    if (jwt1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt1}`
        }
      })
    }
    return next.handle(request);
  }

}
