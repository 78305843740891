import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  patientId: any
  constructor(private _router: Router) {
    this.patientId = this.getSessionStorage('pid');
  }

  encryptData(data: any): string {
    const encryptedData = AES.encrypt((data), environment.secretKey).toString();
    return encryptedData;
  }

  decryptData(encryptedData: any): any {
    try {
      const decryptedBytes = AES.decrypt(encryptedData, environment.secretKey);
      const decryptedData = JSON.parse(decryptedBytes.toString(enc.Utf8));
      return decryptedData;
    }
    catch (error: any) {
      console.log("Error ", error.message)
      // if(error.message.includes("Malformed UTF-8 data")){
      //   // console.log("Error includes--- ",error.message)
      //   this.removeAllStorage()
      //   //   this._router.navigate(['/clinic']);
      //  }
    }
  }


  public setSessionStorage(key: any, value: any) {
    // return sessionStorage.setItem(key, this.encryptData(value))

    if (value === "null") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "[]") {
      return sessionStorage.setItem(key, (value))
    } else {
      return sessionStorage.setItem(key, this.encryptData(value))
    }
  }

  public getSessionStorage(key: any) {

    // if (sessionStorage.getItem(key)) {
    //   let decryptSessionValue = this.decryptData(sessionStorage.getItem(key));
    //   return (decryptSessionValue);
    // } else {
    //   return (sessionStorage.getItem(key));
    // }
    if (sessionStorage.getItem(key)) {
      var value = sessionStorage.getItem(key)
      if (value === "null") {
        return value
      } else if (value === "") {
        return value
      } else if (value === "[]") {
        return value
      } else {
        return this.decryptData(sessionStorage.getItem(key));
      }
    }
    else {
      return ""
    }
  }

  public removeSessionStorageBykey(key: any) {
    if (key) {
      sessionStorage.removeItem(key);
    }
  }

  removeAllStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    sessionStorage.clear()
  }

  public setLocalStorage(key: any, value: any) {
    let encryptLocalValue = JSON.stringify(value);
    localStorage.setItem(key, this.encryptData(encryptLocalValue))
  }

  public getLocalStorage(key: any) {
    if (localStorage.getItem(key)) {
      let decryptLocalValue = this.decryptData(localStorage.getItem(key));
      return decryptLocalValue;
    } else {
      return localStorage.getItem(key);
    }
  }

  public removeLocalStorageBykey(key: any) {
    if (key) {
      localStorage.removeItem(key);
    }
  }

  removeAllLocalStoarage(){
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    localStorage.clear()
  }
}
