import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClinicDashboardComponent } from './main/tablet-in-clinic-view/clinic-dashboard/clinic-dashboard.component';
import { ClinicLockScreenComponent } from './main/tablet-in-clinic-view/clinic-lock-screen/clinic-lock-screen.component';
import { ConfirmPatientComponent } from './main/tablet-in-clinic-view/confirm-patient/confirm-patient.component';
import { LogoutMessagesComponent } from './main/pages/timeout/logout-messages/logout-messages.component';
import { ClinicGuard } from './core/guard/clinic.guard';
import { ClinicDashboardPracticeListComponent } from './main/tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-practice-list/clinic-dashboard-practice-list.component';
import { PreRegisterPatientResolver } from './core/resolvers/pre-register-patient.resolver';
import { ClinicDashboardPatientPreviewComponent } from './main/tablet-in-clinic-view/clinic-dashboard/component/clinic-dashboard-patient-preview/clinic-dashboard-patient-preview.component';
import { VerifyGuard } from './core/guard/verify.guard';
import { OtpLoginComponent } from './alt-views/login/otp-login/otp-login.component';
import { OtpVerifyComponent } from './alt-views/login/otp-verify/otp-verify.component';
import { RestrictGuard } from './core/guard/restrict.guard';
import { MobileGuard } from './core/guard/mobile.guard';
import { ValidateURLComponent } from './alt-views/login/validate-url/validate-url.component';
import { ShowWarningComponent } from './alt-views/login/show-warning/show-warning.component';
import { NavigationGuard } from './core/guard/navigation.guard';
import { PatientGuard } from './core/guard/patient.guard';
import { PageNotFoundComponent } from './alt-views/login/page-not-found/page-not-found.component';
import { PermissionErrorComponent } from './alt-views/permission-error/permission-error.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'clinic',
    pathMatch: 'full',

  },
  {
    path: 'app/logout-messages',
    component: LogoutMessagesComponent
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'clinic',
    component: ClinicLockScreenComponent,
    // canActivate: [RestrictGuard]
  },
  {
    path: 'practice-list',
    component: ClinicDashboardPracticeListComponent,
    canActivate: [ClinicGuard]
  },
  {
    path: 'pre-register-patient/:id/:sessionId',
    component: ClinicDashboardPatientPreviewComponent,
    canActivate: [ClinicGuard],
    resolve: [PreRegisterPatientResolver]
  },
  {
    path: 'verify/:id',
    component: ConfirmPatientComponent,
    canActivate: [RestrictGuard],
  },
  {
    path: 'clinic-dashboard',
    component: ClinicDashboardComponent,
    canActivate: [ClinicGuard]
  },
  {
    path: 'login/:id',
    component: OtpLoginComponent,
    // canActivate: [MobileGuard]
  },

  {
    path: 'otp-verify',
    component: OtpVerifyComponent,
    // canActivate: [MobileGuard]
  },
  {
    path: 'error',
    component: ShowWarningComponent,
    // canActivate: [MobileGuard]
  },
  {
    path: 'permission-error',
    component: PermissionErrorComponent,
    // canActivate: [MobileGuard]
  },
  {
    path: 'auth',
    component: ValidateURLComponent,
    // canActivate: [MobileGuard]
  },

  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    // canActivate: [MobileGuard]
  },

  {
    path: 'info-page',
    component: ShowWarningComponent,
    // canActivate: [MobileGuard]
  },
  {
    path: '**',
    redirectTo: 'app',
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
