<div class="timeout_warning_dialog">
    <kendo-dialog class="k-backdrop-blur-lg">
        <div class="timeout_warning_main text-center">
            <div class="mb-10">
                <ion-text class="bodyL-txt-medium" color="gray100">Are you still there?</ion-text>
            </div>
            <div class=" mb-20">
                <ion-text class="bodyS-txt" color="gray80">You’ve been inactive for while. For your security, we will
                    automatically log you out in:</ion-text>
            </div>
            <div class="mb-20">
                <!-- <ion-text class="timefont" color="gray60">{{minutes}}<span class="light-font-weight">min </span>{{seconds}}<span class="light-font-weight">secs</span></ion-text>   -->
                <ion-text class="timefont" color="gray60"><span class="light-font-weight"> </span>{{counter}}<span
                        class="light-font-weight"> sec(s)</span></ion-text>
            </div>
            <div class="mb-20">
                <!-- <ion-progress-bar color="primary" [buffer]="buffer" [value]="progress"></ion-progress-bar> -->
            </div>
            <div class="d-flex ion-align-items-center ion-justify-content-between timeoutlink">

                <!-- Tablet-in-clinic patient-view -->
                <!-- <ion-button fill="outline" color="highlight" class="filled-outline-btn capitalize w-100 mr-10"
                    routerLink="/app/logout-messages" (click)="onClose()" [routerLink]="['/app/patient-lock']">
                    <ion-text>Lock Tablet</ion-text>
                </ion-button> -->
                <!-- End of Tablet-in-clinic patient-view -->
                <ion-button fill="outline" color="highlight" class="capitalize filled-outline-btn w-100 mr-10"
                     (click)="logout()"> <ion-text>Log out</ion-text>
                </ion-button>
                <!-- (click)="logout()" -->
                <ion-button fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100"
                    (click)="stay()" (click)="onClose()">
                    <ion-text>Stay Connected</ion-text>
                </ion-button>
            </div>
        </div>
    </kendo-dialog>
</div>