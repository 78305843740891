import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { codeSnippetIcon } from '@progress/kendo-svg-icons';
import { Observable, of, switchMap, tap } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { LoginService } from 'src/app/core/tablet-service/login.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-clinic-dashboard-practice-list',
  templateUrl: './clinic-dashboard-practice-list.component.html',
  styleUrls: ['./clinic-dashboard-practice-list.component.scss'],
})
export class ClinicDashboardPracticeListComponent implements OnInit {

  isActive: boolean = false;
  practiceName: any[] = []; // Initialize this with the list of tenants
  piDetails: any;
  token: any;
  uid: any;
  selectedTenant: any; // To store the selected practice object
  searchInput: string = '';
  userRole: any;
  tenantStaus: boolean;

  constructor(private commonService: CommonService, private router: Router, private loginService: LoginService,
    private paymentService: PaymentService, private auditService: AuditService,
    private patientService: PatientService) { }

  ngOnInit() {
    this.userRole = this.commonService.getSessionStorage('roles');
    this.piDetails = this.commonService.getSessionStorage('PI');
    this.token = this.piDetails.token;
    this.uid = this.commonService.getSessionStorage('UID');
    this.loginService.getQoreUserDetails(this.uid, this.token).subscribe((response: any) => {
      if (response) {
        this.practiceName = response.tenants;
      }
    });
  }


  //  practiceName : { name: string; active: boolean }[] = [
  //     {
  //       name: 'Practice 1',
  //       active: false
  //     },
  //     {
  //       name: 'Practice 2',
  //       active: false
  //     },
  //     {
  //       name: 'Practice 3',
  //       active: false
  //     },
  //     {
  //       name: 'Practice 4',
  //       active: false
  //     },
  //     {
  //       name: 'Practice 5',
  //       active: false
  //     },

  //   ]; 

  checkEnabledTenant(getTenantId: string): Observable<any> {
    return this.patientService.getTenantStatus(getTenantId).pipe(
      tap((res: any) => {
        this.tenantStaus = res?.intakeenabled;
      })
    );
  }
  
  
  async connectToTenant() {
    let audit = this.commonService.getSessionStorage('redirectTo');
    // Find the selected tenant
    const selectedTenant = this.practiceName.find((practice) => practice.active);
    if (selectedTenant.tenantid) {
      this.commonService.setSessionStorage('tnt', JSON.stringify(selectedTenant.tenantid));
      this.commonService.setSessionStorage('TN', JSON.stringify(selectedTenant.tenantname));
      // await this.clearGageCredentials();
      this.searchInput = '';
      this.auditService.writeOparationalLog('Connect To Tenant#' + selectedTenant.tenantname + '#' + selectedTenant.tenantid);
      if (this.commonService.getSessionStorage('redirectTo') == 'auditreport') {
        this.router.navigate(['/app/audit-report']);
      }
      else if (this.commonService.getSessionStorage('redirectTo') == 'usagereport') {
        this.router.navigate(['/app/usage-report']);
      }
      else  if (this.commonService.getSessionStorage('tnt') == selectedTenant.tenantid && !audit && this.userRole?.includes("Clinic Dashboard") && this.tenantStaus) {
        this.router.navigate(['/clinic-dashboard']);
      }
      else if (this.commonService.getSessionStorage('tnt') == selectedTenant.tenantid  && this.userRole?.includes("Clinic Dashboard") && this.tenantStaus) {
        this.router.navigate(['/clinic-dashboard']);
      }
      else if (this.commonService.getSessionStorage('tnt') == selectedTenant.tenantid  && this.userRole?.includes("Clinic Dashboard") && !this.tenantStaus) {
        localStorage.removeItem('practiceDetails')
        this.loginService.permissionError()
      }
      else if (!this.userRole?.includes("Clinic Dashboard") && this.userRole?.includes("Audit Report")) {
        this.router.navigate(['/app/audit-report']);
      }
      else if (this.commonService.getSessionStorage('tnt') == selectedTenant.tenantid && audit) {
        this.router.navigate(['/app/audit-report']);
      }
    } 
    else {
      console.log('No tenant selected');
    }
  }



  async clearGageCredentials() {
    let tenantid = this.commonService.getSessionStorage('tnt');
    this.paymentService.getTenantClearGageCredentials(tenantid).subscribe((response: any) => {
      if (response) {
        const cleargageCredentials = JSON.stringify(response.data);
        return this.commonService.setSessionStorage('tenantcleargageCred', cleargageCredentials);
      } else {
        return this.commonService.removeSessionStorageBykey('tenantcleargageCred');
      }
    });
  }

  activePracticeList(clickItem: { tenantname: string; active: boolean; tenantid: any }) {
    this.practiceName.forEach((practice) => (practice.active = false));
    clickItem.active = true;
    this.isActive = true;
    // Proceed with checking tenant status
    this.checkEnabledTenant(clickItem.tenantid).subscribe(() => {
      this.selectedTenant = clickItem;
      this.commonService.setLocalStorage('practiceDetails', this.selectedTenant);
    });
  }
  
}