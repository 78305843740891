<div class="warningScreen_main">
  <div class="warningScreen_content">
    <ion-icon src="../../../../assets/svg/alert-triangle.svg" class="warning_icon d-block mb-20"></ion-icon>
    <div class="column error-content mb-20">
      <ion-text class="body-txt-18 mb-5" color="gray100">Please use a tablet or 
        a web browser </ion-text>
      <ion-text class="bodyM-txt">to access 
          the application</ion-text>
    </div>

      
    <div>
      <ion-button fill="solid" color="white" class="filled-btn warning_btn">
        <ion-text class="h5 roboto text-uppercase">OK</ion-text>
      </ion-button>
    </div>  
  </div>
</div>